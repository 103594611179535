import React,{ Component } from 'react';
import icondRojo from '../../assets/file rojo small.svg';
import icondNegro from '../../assets/file negro small.svg';
class List extends Component{
  render(){
    console.log(this.props);
    
    return(
      <div className="card-body border-collapse">
        <div style={{width:"100%", marginRight:"62px"}}>
          <h3>{this.props.name}</h3>
          <p>{this.props.description}</p>
        </div>
        <div className="content-enlace">
          <a href={this.props.urlDataSheet} target="__blank" className="enlace" download>
            <img src={icondRojo} alt="Icono de descarga rojo"/>
            <span>FICHA TEC.</span>
          </a>
          <a href={this.props.urlSafetyDataSheet} target="__blank" className="enlace" download>
            <img src={icondNegro} alt="Icono de descarga negro"/>
            <span>HOJA SEG..</span>
          </a>
        </div>
      </div>
    )
  }
}
export default List
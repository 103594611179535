import React, { Component } from 'react';

class slideItem extends Component{
    handleNextId=(e)=>{
        const newtitle=this.limpia_espacios(e.target.name)
        this.props.history.push(`/Noticias-Id/${e.target.dataset.id}-${newtitle}`)
    }
    limpia_espacios=(cadena)=>{
        cadena =  cadena.replace(/\s/g,"-");
        return (cadena);
    }
    render(){
        return(
            <li className={ this.props.index === this.props.activeIndex
                ? "carousel__slide carousel__slide--active"
                : "carousel__slide" }>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 border-contentImage px-0">
                        <div class="content-img">
                            <div style={{background:`url(${this.props.slide.img})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain", width: "100%",height: "100%"}}></div>
                        </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 px-0">
                        <div class="content-info">
                            <h4 class="blog-slider__numbertext">{this.props.slide.title}</h4>
                            <p class="blog-slider__text">{this.props.slide.content}</p>
                            <button name={this.props.slide.title} data-id={this.props.slide.id} onClick={this.handleNextId}>Ver más</button>
                        </div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}
export default slideItem;
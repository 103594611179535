import React, { Component } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/footer';
import Home from '../Components/Quimicos/home';
class Quimicos extends Component{
    render(){
        return(
            <div>
                <Header name="Quimicos"/>
                <Home />
                <Footer history={this.props.history}/>
            </div>
        )
    }
}
export default Quimicos;
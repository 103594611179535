import React, { Component } from 'react';
import pdfIconoNegro from '../../../assets/file negro small.svg';
import pdfIconoRojo from '../../../assets/file rojo small.svg';
import {URLProducts,URLCategorias} from '../../../auth/apis';
import axios from 'axios';

class ModalProduct extends Component{
  constructor(){
    super();
    this.state={
      categoria:"",
      producto:"",
      descripcion:"",
      nameFicha:'',
      ficha:"",
      extFicha:"",
      nameSeguridad:"",
      extSeguridad:"",
      seguridad:"",
      selects:[]
    }
  }
  componentDidMount(){
    this.getCategoria()
  }
  getCategoria=()=>{
    const { selects } = this.state; 
    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    axios.get(URLCategorias,{headers})
    .then(data=>{      
      data.data.categories.forEach(element => {
        selects.push({
          id:element.id,
          name:element.name
        })
      });
      this.setState({ selects })
    })
  }

  handleChangeFile=(e)=>{    
    
    if(e.target.name==="ficha"){
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = ()=>this.setState({ficha: reader.result})
      this.setState({
        nameFicha:e.target.files[0].name,
        extFicha:e.target.value})
    } else{
      this.setState({[e.target.name]: e.target.value})
    }
  }
  handleChangeFileS=(e)=>{    
    this.setState({nameSeguridad:e.target.files[0].name,extSeguridad:e.target.value})
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = ()=>{this.setState({seguridad:reader.result})}
  }

  getFileExtension1=(filename) =>{
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }
  handleReset = async ()=>{
    await this.setState({
      categoria:"",
      producto:"",
      descripcion:"",
      nameFicha:'',
      ficha:"",
      extFicha:"",
      nameSeguridad:"",
      extSeguridad:"",
      seguridad:"",
    })
  }
  handleSubmit = () =>{
    const { categoria,producto,descripcion,ficha,seguridad } = this.state;
    const { getData } = this.props;
    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }

    const body={
      "name":producto,
      "description":descripcion,
      "dataSheet":ficha,
      "safetyDataSheet":seguridad,
      "categoryId":Number(categoria)
    }    
    if(categoria !== "" && producto !== " " && descripcion !== "" && ficha !== " " && seguridad !== " "){
      axios.post(URLProducts,body,{headers})
      .then(res=>{
        console.log(res);
        if(res.status===200){
          alert("Se creo correctamente el producto")
          getData()
          this.setState({
            categoria:"",
            producto:"",
            descripcion:"",
            nameFicha:'',
            ficha:"",
            extFicha:"",
            nameSeguridad:"",
            extSeguridad:"",
            seguridad:"",
          })
        }else{
          alert("Error en el servidor, llame a su consultor")
        }
      })
    }else{
      alert("Ingresa todos lo campos")
    }
  }

  createSelectItems=()=>{
    const { selects} = this.state;
    let items=[];
    selects.forEach(data=>{      
      items.push(<option key={data.id} value={data.id}>{data.name}</option>)
    })
    return items;
  }

  render(){ 
    const { categoria,producto,descripcion}=this.state    
    const extFicha=this.getFileExtension1(this.state.extFicha) || '';
    const extSeguridad=this.getFileExtension1(this.state.extSeguridad) || ""
           
    return(
      <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Nuevo Producto</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-6">
                <label className="label" htmlFor="form1">Categoría</label>
                <div className="md-form">
                  <select name="categoria" value={categoria} onChange={this.handleChangeFile} className="browser-default custom-select">
                    <option value="select">Selecciona una categoría</option>
                    {this.createSelectItems()}
                  </select>                
                </div>
              </div>
              <div className="col-6">
                <label className="label" htmlFor="form2">Nombre del Producto</label>
                <div className="md-form">
                  <input type="text" id="form2" name="producto" className="form-control product" value={producto} onChange={this.handleChangeFile}/>
                </div>
              </div>
              <div className="col-12">
                <label className="label" htmlFor="form3">Descripción del producto</label>
                <div className="form-group">
                  <textarea className="form-control rounded-0 product" id="form3" name="descripcion" rows="3" value={descripcion} onChange={this.handleChangeFile}></textarea>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex px-15 justify-content-between info-product">
                  <p className="mb-0 align-self-center namesFichas">Ficha Técnica</p>
                  <p className="iconopdf">
                    {(extFicha==="pdf")?
                      <img className={(this.state.extFicha!=='')?"showImage":"noneImage"} src={pdfIconoRojo} alt="imageFondo"/>:
                      (extFicha==="")?"":
                      <span>No es un archivo .pdf</span>
                    }
                    <span>{this.state.nameFicha}</span>
                  </p>
                  <div className="input-file-container">  
                    <input className="input-file" name="ficha" id="my-file" type="file" onChange={this.handleChangeFile}/>
                    <label tabIndex="0" htmlFor="my-file" className="input-file-trigger">Agregar archivo</label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex px-15 justify-content-between info-product">
                  <p className="mb-0 align-self-center namesFichas">Hoja de Seguridad</p>
                  <p className="iconopdf">
                    {(extSeguridad==="pdf")?
                      <img className={(this.state.extSeguridad!=='')?"showImage":"noneImage"} src={pdfIconoNegro} alt="imageFondo"/>:
                      (extSeguridad==="")?"":
                      <span>No es un archivo .pdf</span>
                    }
                     <span>{this.state.nameSeguridad}</span>
                  </p>
                  <div className="input-file-container">  
                    <input className="input-file" id="my-file" name="seguridad" type="file" onChange={this.handleChangeFileS}/>
                    <label tabIndex="0" htmlFor="my-file" className="input-file-trigger">Agregar archivo</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="modalBtn backgroung-btnBlack px-4" onClick={this.handleReset} data-dismiss="modal">Regresar</button>
            <button type="button" className="modalBtn backgroung-btnRed px-4" onClick={this.handleSubmit}>Guardar</button>
          </div>
      </div>
    )
  }
}
export default ModalProduct;
import React, { Component } from 'react';
import fondo from '../../assets/cielo.png';
import { URLHomeNoticias } from '../../auth/apis'
import {renderToString} from 'react-dom/server'
import axios from 'axios';

class AllNoticias extends Component{
  constructor(){
    super()
    this.state={
      listItems:[ ]
    }
  }
  componentDidMount = async () =>{
    await this.getNoticias()
  }
  getNoticias = async() =>{
    let newData=[]
    await axios.get(URLHomeNoticias)
    .then(res=>{
      res.data.news.map(element => {
        let tempData={
          id:element.id,
          title:element.title,
          shortDescription:element.shortDescription,
          urlImage:element.urlImage,
          dateCreated:element.dateCreated
        }
        newData.push(tempData);
      });
    })
    await this.setState({ listItems:newData })
  }
  limpia_espacios=(cadena)=>{
    cadena =  cadena.replace(/\s/g,"-");
    return (cadena);
}
  handleNext=(e)=>{
    const newtitle=this.limpia_espacios(e.target.name)
    this.props.history.push(`/Noticias-Id/${e.target.dataset.id}-${newtitle}`)
  }
  handleConvert = (data) =>{
    const newData = data.split(/\s/).reduce(function (prev, cur) {
                    
      if (cur.indexOf('http') === 0) {
          cur = '<a href="' + cur + '">' + cur + '</a>';
      }
      //word starts with www; convert to http://+link
      else if (cur.indexOf('www') === 0) {
        cur = '<a href="http://' + cur + '">' + cur + '</a>';
      }
      //append space to display properly
      return prev + cur + ' '
  }, '');
  return <p>{newData}</p>
  }
  render(){
    let textoP=null
    return(
      <section className="section-certificacion">
        <div className="title-section">
          <div>
            <img src={fondo} alt="fondo titulo"/>
          </div>
          <div className="titleContent">
            <h3>Noticias</h3>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 pt-25 pb-25" >
              {
                this.state.listItems.map((data,i)=>{
                  const urlRegex = /(http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/g;
                  const url = data.shortDescription.match(urlRegex)
                  // const arrayText = data.shortDescription.split(" ")
                  return             (<div key={i} className={"card  background-general mt-3 mb-3"+((this.state.listItems.length-1)===i?" ":" border-bottom-1")}>
                  <img className="card-img-top" src={data.urlImage} alt={data.title} />
                  <div className="card-body">
                    <h4 className="card-title">{data.title}</h4>
                      <p className="card-text">{data.shortDescription}</p>
                    <a href="#" className="btn-next" name={data.title}  data-id={data.id} onClick={this.handleNext}>Leer más</a>
                  </div>
                </div>)
                })
              }             
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default AllNoticias 
import React, { Component } from 'react';
import Header from '../Components/Header';
import Content from '../Components/home/Content';
import Noticias from '../Components/home/slideNoticias';
import Footer from '../Components/footer';
import axios from 'axios';
import { URLNoticiasRecientes } from '../auth/apis';

class Main extends Component{
  constructor(props){
    super(props)
    this.state={
      listItems:[] 
    }
  }
  componentWillMount = async () =>{    
    await this.getItems();
  }
  getItems = async () =>{
    let newData = []
    await axios.get(URLNoticiasRecientes)
    .then(res=>{      
      res.data.news.map(element => {
          let tempData={
            id:element.id,
            title:element.title,
            content:element.shortDescription,
            img:element.urlImage
          }
          newData.push( tempData )
      });
    })
    await this.setState({ listItems:newData })

  }
 
  render(){    
    return(
      <section>
        <Header name="Inicio"/>
        <Content />
        <Noticias history={this.props.history} slides={this.state.listItems}/>
        <Footer history={this.props.history} handleSubmit={this.handleSubmit}/>
      </section>
    )
  }
}
export default Main;
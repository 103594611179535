import React, { Component } from 'react';
import Modal from './Modal';
import { URLGetLimits } from './../../auth/apis';
import ModalCreate from './ModalCreate'
import axios from 'axios';
class TableLimites extends Component{
  constructor(){
    super()
    this.state={
      data:[]
    }
  }
  componentDidMount = async ()=>{
    await this.getLimit()
  }
  getLimit = async () =>{
    let newdata = []

    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    await axios.get(URLGetLimits,{headers})
    .then(res=>{
      res.data.limitsAndDiaries.map(element => {
        let tempData = {
          id:element.id,
          category:element.category,
          files:element.files
        }
        newdata.push(tempData)
      });
    })
    await this.setState({data:newdata})

  }
  render(){
    return(
      <div className="tab-pane fade" id="limit" role="tabpanel" aria-labelledby="limit-tab">
            <div className="addbtn">
              <button type="button" data-toggle="modal" data-target="#limit-add" className="modalBtn backgroung-btnRed">Nueva Categoría</button>
              <ModalCreate name="limit" codigo="-add" getData={()=>this.getLimit()}/>
            </div>
            <div className="table-responsive">
            <table className="table table-striped table-background">
              <thead>
                <tr>
                  <th className="px-2" scope="col">Código</th>
                  <th className="px-2" scope="col">Categoria</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
              {
                  this.state.data.map((data,key)=>
                    <tr key={key}>
                      <td className="px-2">{data.id}</td>
                      <td className="px-2">{data.category}</td>
                      <td>                
                        <button type="button" className="linkMore" data-toggle="modal" data-target={"#limits"+data.id}>
                          Editar
                        </button>
                        <Modal 
                          name="limits" 
                          codigo={data.id}
                          categoria={data.category}
                          files={data.files}
                          getData={()=>this.getLimit()}
                          />
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>            
            </div>
          </div>
    )
  }
}
export default TableLimites;
import React, { Component } from 'react';
import TableProduct from './TableProduct';
import TableNoticia from './TableNoticia';
import TableKits from './TableKits';
import TableLimites from './TableLimites';
class ContentTab extends Component{

  render(){
    return(
      <section className="content-table">
        <div className="nav-table">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="product-tab" data-toggle="tab" href="#product" role="tab" aria-controls="product"
                aria-selected="true">Productos</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="noticia-tab" data-toggle="tab" href="#noticia" role="tab" aria-controls="noticia"
                aria-selected="false">Noticias</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="testKit-tab" data-toggle="tab" href="#testKit" role="tab" aria-controls="testKit"
                aria-selected="false">Test Kits</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="limit-tab" data-toggle="tab" href="#limit" role="tab" aria-controls="limit"
                aria-selected="false">Límites y Diarios</a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="myTabContent">
          <TableProduct />
          <TableNoticia/>
          <TableKits/>
          <TableLimites/>
        </div>
      </section>
    )
  }
}
export default ContentTab;
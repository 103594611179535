import React, { Component } from 'react';
import iconPdf from '../../../assets/icons8-pdf-2-filled.svg';
import axios from 'axios';
import { URLKits } from './../../../auth/apis';
class ModalKit extends Component{
  constructor(props){
    super(props);
    this.state={
      nombre:"",
      descripcion:"",
      image:"",
      nameImage:"",
      file:"",
      nameFile:"",
    }
  }
  handleChangeFile=(e)=>{    
    if(e.target.name==="file"){
      var reader = new FileReader();
      reader.onload = ()=>this.setState({file: reader.result})
      reader.readAsDataURL(e.target.files[0]);
      this.setState({nameFile:e.target.files[0].name})
    } else{
      this.setState({[e.target.name]: e.target.value})
    }
  }
  handleChangeFileS=(e)=>{    
    this.setState({nameImage:e.target.files[0].name})
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = ()=>{this.setState({image:reader.result})}
  }

  getFileExtension1=(filename) =>{
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }
  handleReset = async ()=>{
    await this.setState({
      nombre:"",
      descripcion:"",
      image:"",
      nameImage:"",
      file:"",
      nameFile:"",
    })
  }
  handleSubmit = () =>{
    const { nombre,descripcion,image,file } = this.state;
    const { getData } = this.props;

    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    const body={
      "name":nombre,
      "description":descripcion,
      "image":image,
      "file":file,
    }    
    if(nombre !== " " && descripcion !=="" && image!=="" && file!==""){
      axios.post(URLKits,body,{headers})
      .then(res=>{
        if(res.status===200){
          alert("Se creo correctamente el testKit")
          getData()
          this.setState({
            nombre:"",
            descripcion:"",
            image:"",
            nameImage:"",
            file:"",
            nameFile:"",
          })
        }else{
          alert("Error en el servidor, llame a su consultor")
        }
        console.log(res);
      })
    }else{
      alert("Completar todos los campos")
    }
  }
  render(){    
    const { nombre,descripcion }=this.state    
    const extFile=this.getFileExtension1(this.state.nameFile) || '';
    const extImage=this.getFileExtension1(this.state.nameImage) || ""    
    return(
      <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Nuevo Test Kit</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <label className="label" htmlFor="form3">Nombre Test Kit</label>
                <div className="md-form">
                  <input type="text" id="form1" name="nombre" className="form-control" value={nombre} onChange={this.handleChangeFile}/>
                </div>
              </div>
              <div className="col-12">
                <textarea type="text" id="exampleForm2" name="descripcion" className="form-control" placeholder="Descripcion" value={descripcion} onChange={this.handleChangeFile}/>
              </div>
              <div className="col-12">
                <div className="d-flex px-15 justify-content-between info-product">
                  <p className="mb-0 align-self-center namesFichas">Imagen</p>
                  {
                      (this.state.image === "")?<p></p>:
                      !(/\.(jpg|png|gif|jpeg)$/i).test(extImage)?
                      <p className="iconopdf">
                        <img src={this.state.image} alt="imageFondo"/>
                        <span>{this.state.nameImage}</span>
                      </p>
                      :<p className="iconopdf"><span>No es una imagen</span></p>
                    }
                  <div className="input-file-container">  
                    <input className="input-file" name="image" id="my-file" type="file" onChange={this.handleChangeFileS}/>
                    <label tabIndex="0" htmlFor="my-file" className="input-file-trigger">Agregar archivo</label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex px-15 justify-content-between info-product">
                  <p className="mb-0 align-self-center namesFichas">Ficha Técnica</p>
                  {
                    (this.state.file === "")?<p></p>:
                    (extFile==="pdf")?
                    <p className="iconopdf">
                      <img src={iconPdf} alt="imageFondo"/>
                      <span>{this.state.nameFile}</span>
                    </p>
                    :<span>No es un archivo .pdf</span>
                  }
                  <div className="input-file-container">  
                    <input className="input-file" name="file" id="my-file" type="file" onChange={this.handleChangeFile}/>
                    <label tabIndex="0" htmlFor="my-file" className="input-file-trigger">Agregar archivo</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="modalBtn backgroung-btnBlack px-4" onClick={this.handleReset} data-dismiss="modal">Regresar</button>
            <button type="button" className="modalBtn backgroung-btnRed px-4" onClick={this.handleSubmit}>Guardar</button>
          </div>
      </div>
    )
  }
}
export default ModalKit;
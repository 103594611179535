import React,{ Component } from 'react';
import SlideItem from './slideItem';

class Noticias extends Component{
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        };
        this.goToPrevSlide = this.goToPrevSlide.bind(this);
        this.goToNextSlide = this.goToNextSlide.bind(this);
      }
    
      goToPrevSlide(e) {
        e.preventDefault();
        let index = this.state.activeIndex;
        let { slides } = this.props;
        let slidesLength = slides.length;
        if (index < 1) {
          index = slidesLength;
        }
        --index;
        this.setState({
          activeIndex: index
        });
      }
    
      goToNextSlide(e) {    
        let index = this.state.activeIndex;
        let { slides } = this.props;
        let slidesLength = slides.length - 1;
        if (index === slidesLength) {
          index = -1;
        }
        ++index;
        this.setState({
          activeIndex: index
        });
      }
    
    handleNoticias=()=>{
        this.props.history.push('/Noticias')
    }
    render(){
        
        return(
            <section class="section-slide">
                <div class="container">
                    <div class="row">
                        <div className="col-12">
                            <h3>Noticias</h3>
                        </div>
                        <div className="col-12">
                            <div className="carousel">
                                <ul className="carousel__slides">
                                    {this.props.slides.map((slide, index) =>
                                        <SlideItem
                                        key={index}
                                        index={index}
                                        activeIndex={this.state.activeIndex}
                                        slide={slide}
                                        history={this.props.history}
                                        />
                                        )
                                    }
                                </ul>
                                <div className="content-btn">
                                    <button className="prev" value="Prev"  onClick={e => this.goToPrevSlide(e)}><i class="fas fa-arrow-left"></i></button>   
                                    <button className="next" value="Next" onClick={e => this.goToNextSlide(e)}><i class="fas fa-arrow-right"></i></button>          
                                </div>
                            </div>
                        </div>
                        <button class="noticiasMas" onClick={this.handleNoticias}>Ver más noticias</button>
                    </div>
                </div>
            </section>
        )
    }
}
export default Noticias;
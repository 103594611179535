import React, { Component } from 'react';
import {URLLimits,URLCategorias} from '../../../auth/apis';
import iconMore from '../../../assets/icons8-más-filled.svg';
import iconExcel from '../../../assets/icons8-xls-filled.svg';
import iconPdf from '../../../assets/icons8-pdf-2-filled.svg';
import axios from 'axios';

class ModalKits extends Component{
  constructor(props){
    super(props);
    this.state={
      categoria:"",
      selects:[],
      nameFile:{},
      extFile:{},
      files:[{name:"",file:""}]
    }
  }
  componentDidMount(){
    this.getCategoria()
  }
  
  getCategoria=()=>{
    const { selects } = this.state; 
    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    axios.get(URLCategorias,{headers})
    .then(data=>{      
      data.data.categories.forEach(element =>
        selects.push({
          id:element.id,
          name:element.name
        })
      );
      this.setState({selects})
    })
  }

  getFileExtension1=(filename) =>{
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  createSelectItems=()=>{
    const { selects} = this.state;
    let items=[];
    selects.forEach(data=>{      
      items.push(<option key={data.id} value={data.id}>{data.name}</option>)
    })
    return items;
  }

  handleShareholderNameChange =  e => {
    if(["name","file"].includes(e.target.name)){
      let files = [...this.state.files];
      files[e.target.dataset.id][e.target.name]=e.target.value;
      this.setState({files})
    }else{
      this.setState({[e.target.name]:e.target.value})
    }
  };

  handleAddShareholder = () => {
    this.setState((prevState) =>({
      files:[...prevState.files, {name:"",file:""}]    }));
  };

  handleRemoveShareholder = idx => () => {
    this.setState({
      files: this.state.files.filter((s, sidx) => idx !== sidx)
    });
  };

  handleShareholderNameChangeFile = e => {    
    let id=e.target.dataset.id;
    let name=e.target.name
    let extFile = this.getFileExtension1(e.target.files[0].name)    
    this.setState({
      nameFile:{
        ...this.state.nameFile,
        [id]:e.target.files[0].name},
      extFile:{
        ...this.state.extFile,
        [id]:extFile
      }
      })
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e)=>{
      let files = [...this.state.files]
      files[id][name]=reader.result; 
      console.log(reader.result);
                 
      this.setState({files})
    }
  };

  handleReset = async ()=>{
    await this.setState({
      categoria:"",
      files:[{name:"",file:""}]
    })
  }

  handleSubmit = () =>{
    const { categoria,files } = this.state;    
    const { getData } = this.props;

    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    const id=Number(categoria)
    const body={
      "categoryId":id,
      "files":files
    }        
  
    if(categoria !=="" && files.length !==0){
      axios.post(URLLimits,body,{headers})
      .then(res=>{
        console.log(res);
        if(res.status === 200){
          alert("Se creo correctamente el limite y diario")
          getData()
          this.setState({
            categoria:"",
            files:[{name:"",file:""}]
          })
        }else{
          alert("Error en el servidor, llame a su consultor")
        }
      })
    }else{
      alert("Completa todos los campos")
    }
  }

  render(){  
    const { categoria }=this.state            
    return(
      <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Nuevos Limites</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-8">
                <label className="label" htmlFor="form3">Ingresa Categoria</label>
                <select name="categoria" value={categoria} onChange={this.handleShareholderNameChange} className="browser-default custom-select">
                  <option value="select">Selecciona una opción</option>
                  {this.createSelectItems()}
                </select>
              </div>
              <div className="col-12 mt-3 d-flex" style={{alignItems:"center"}}>
                <div>
                  <p className="mb-0 titleLimitProdut">Nombre del Documento</p>
                </div>
                <div className="limitIconAdd">
                  <button className="btnAdd" onClick={this.handleAddShareholder}><img src={iconMore}/></button>
                </div>
              </div>
              {
                this.state.files.map((file,idx)=>{                  
                  let fileId=`file-${idx}`, nameId=`name-${idx}`
                  return (
                  <div className="col-12" key={idx}>
                    <div className="d-flex px-15 justify-content-between info-product Limit">
                      <div className="">
                        <div className="md-form mb-0">
                          <input 
                            type="text"
                            data-id={idx}
                            id={nameId} 
                            name="name" 
                            className="form-control mb-0" 
                            value={file.name} onChange={this.handleShareholderNameChange}/>
                        </div>
                      </div>
                      <p className="iconopdf limit">
                        {(file.file!=="")?
                          <img src={(this.state.extFile[idx] === "xlsx" || this.state.extFile[idx] === "xls")?iconExcel:iconPdf} alt="imageFondo"/>:""
                        }
                      </p>
                      <div className="input-file-container">  
                        <input 
                          className="input-file" 
                          data-id={idx}
                          id={fileId} 
                          name="file" 
                          type="file" onChange={this.handleShareholderNameChangeFile}/>
                        <label tabIndex="0" className="input-file-trigger mb-0">{(file.file!==""?"Reemplazar":"Agregar")} archivo</label>
                      </div>
                      <button className="btnDelete ml-0 mr-2" onClick={this.handleRemoveShareholder(idx)}>Eliminar</button>
                    </div>
                  </div>
                  )
                })
              }
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="modalBtn backgroung-btnBlack px-4" onClick={this.handleReset} data-dismiss="modal">Regresar</button>
            <button type="button" className="modalBtn backgroung-btnRed px-4" onClick={this.handleSubmit}>Guardar</button>
          </div>
      </div>
    )
  }
}
export default ModalKits;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LOGO2 from '../assets/Logos uniservice-06.svg';
import LOGO1 from '../assets/Logos uniservice-05.svg'
import Popover from './home/Popover';
import AuthService from './../auth/AuthService';
class footer extends Component{
  constructor(){
    super()
    this.state={
      email:' ',
      password:' '
    }
    this.Auth = new AuthService();
  }
  handleInputChange=(e)=>{
    this.setState({
      [e.target.name]:e.target.value
    })
  }
  handleSubmit =()=>{
    const { email,password} = this.state;
    this.props.handleSubmit(email,password)
  }
  handleSubmit = () =>{
    const { email,password} = this.state;
    this.Auth.login(email,password)
    .then(res=>{
      console.log(res);
      this.props.history.push('/Intranet');
    })
    .catch(err=>
      console.log(err)
      )
  }
  
  componentWillMount(){
    if(this.Auth.loggedIn())
        this.props.history.push('/Intranet');
    }
    render(){
        const { email, password } = this.state;
        return(
          <footer className={"page-footer"+(this.props.show==="history" ? " notShow": " ")} id="contacto">
            <div className="container-fluid text-center">
              <div className="row">
                <div className="col-md-12 col-lg-5 col-xl-6 mt-md-0 mt-3">
                  <div className="content-logo">
                    <img  className="logo-1" src={LOGO1} alt="logo uniservice 1"/>
                    <img className="logo-2" src={LOGO2} alt="logo uniservice 2" />
                  </div>
                </div>
                <hr className="clearfix d-md-none" />
                <div className="col-lg-7 col-xl-6 mb-md-0 mb-3 px-0">
                  <div className="row mx-0">
                    <div className="col-md-4 option text-lg-left showList">
                      <Link to="/Catalogos-Quimicos" >Catálogo y Fichas Técnicas</Link>
                    </div>
                    <div className="col-md-2 option text-lg-left showList">
                    <Link to="/Test-kits">Test Kits</Link>
                    </div>
                    <div className="col-md-6 option text-lg-right showList pr-5">
                      <Link to="/Limites-Diarios">Límites Químicos y Diarios de Control</Link>
                    </div>
                    <div className="col-12 mt-5 text-lg-left contact">
                      <h4 className="mb-3">Asesorías y Contacto</h4>
                      <p><span>E-mail:</span> info@uniservicelatina.com</p>
                      <p><span>Teléfonos de oficina:</span> (+511) 270-6772 / 252-7281</p>
                      <p><span>Teléfonos móviles:</span> (+51) 999 269 495 - 999 572 952</p>
                    </div>
                    <div className="col-4 ml-auto option text-lg-right showList pr-5">
                      <div className="popover_parent">
                      <Popover
                        showArrow
                        triggerNode={<a>
                        <span>INTRANET <hr className="divided" /></span>
                        <i className="fas fa-chevron-up"></i></a>}
                        trigger='click'>
                        <h3>Iniciar Sesión</h3>
                        <form>
                          <label>Ingresa tus datos:</label>
                          <div className="form-group">
                            <input 
                              type="email" 
                              name="email"
                              className="form-control" 
                              id="exampleDropdownFormEmail1" 
                              placeholder="Correo" 
                              onChange={ this.handleInputChange} 
                              value={email}/>
                          </div>
                          <div className="form-group">
                            <input 
                              type="password" 
                              name="password"
                              className="form-control" 
                              id="exampleDropdownFormPassword1" 
                              placeholder="Contraseña" 
                              onChange={this.handleInputChange}
                              value={password}/>
                          </div>
                          <button type="button" className="btn" onClick={ this.handleSubmit }>Ingresa</button>
                        </form>
                        <a className="dropdown-item" href="/Intranet.html">¿Olvidaste tu contraseña?</a>
                    </Popover>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-copyright">
              <p className="mb-2">Abastecimiento de Químicos Marinos en Latinoamérica y el Mundo. ©Uniservice 2019</p>
              <p className="mb-0">Desarrollado por <a className="link-footer" href="https://enfasys-tech.com/" target="__blanck">Enfasys</a></p>
            </div>
          </footer>
        )
    }
}
export default footer;
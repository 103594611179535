import React, { Component } from 'react';
import axios from 'axios';
import { URLNoticias } from '../../../auth/apis'
import iconMore from '../../../assets/icons8-más-filled-1.svg';
import iconDelete from '../../../assets/icons8-delete (1).svg';
import iconEdit from '../../../assets/icons8-edit.svg';

class ModalNoticia extends Component{
  constructor(){
    super()
    this.state={
      title:"",
      shortDescription:"",
      largeDescription:"",
      images:[{
          image:'',
          isMain:true
        }
      ],
    }
  }

  handleChange = (e) =>{
    this.setState({[e.target.name]:e.target.value})
  }
  handleChangeimg=(e)=>{
    let id=e.target.dataset.id;
    let name=e.target.name    
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e)=>{
      let images = [...this.state.images]
      images[id][name]=reader.result;      
      this.setState({images})
    }
  }
  handleChangeRadio = (e) =>{
    let id=e.target.dataset.id;
      let images = [...this.state.images];
      for (let i = 0; i < images.length; i++) {        
          if(Number(id)===i){
          images[i]["isMain"]=true;
          }else{
            images[i]["isMain"]=false;
          }
      }
      this.setState({images})
  }

  handleAddShareholder = () => {
    this.setState((prevState) =>({
      images:[...prevState.images, {image:"",isMain:false}],
    }));
  };

  handleReset = async ()=>{
    await this.setState({
      title:"",
      shortDescription:"",
      largeDescription:"",
      images:[{
          image:'',
          isMain:true
        }
      ],
    })
  }
  handleSubmit = () =>{
    const { title,shortDescription,largeDescription,images } = this.state;
    const { getData } = this.props;

    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    const body={
      "title":title,
      "shortDescription":shortDescription,
      "longDescription":largeDescription,
      "images":images
    }        
    console.log(body);
    
    if(title !== " " && shortDescription !== "" && largeDescription !=="" && images.length !==0 && images[0]["image"]!==""){
      axios.post(URLNoticias,body,{headers})
      .then(res=>{
        if(res.status===200){
          alert("Se creo correctamente el producto")
          getData()
          this.setState({
            title:"",
            shortDescription:"",
            largeDescription:"",
            images:[{
                image:'',
                isMain:true
              }
            ],
          })
        }else{
          alert("Error en el servidor, llame a su consultor")
        }
      })
    }else{
      alert("Agrega todos los campos")
    }

  }
  handleRemoveShareholder = idx => () => {
    this.setState({
      images: this.state.images.filter((s, sidx) => idx !== sidx)
    });
  };

  render(){                    
    return(
      <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Nueva Noticia</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <label className="label" htmlFor="form1">Titulo de Noticia: </label>
                <div className="md-form">
                  <input type="text" name="title" id="form1" className="form-control noticia" value={this.state.title} onChange={this.handleChange}/>
                </div>
              </div>
              <div className="col-12">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id={"pills-corta-tab"+this.props.codigo} data-toggle="pill" href={"#pills-corta"+this.props.codigo} role="tab" aria-controls={"pills-corta"+this.props.codigo} aria-selected="true">Descripción corta</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id={"pills-larga-tab"+this.props.codigo} data-toggle="pill" href={"#pills-larga"+this.props.codigo} role="tab" aria-controls={"pills-larga"+this.props.codigo} aria-selected="false">Descripción larga</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id={"pills-img-tab"+this.props.codigo} data-toggle="pill" href={"#pills-img"+this.props.codigo} role="tab" aria-controls={"pills-img"+this.props.codigo} aria-selected="false">Imágenes</a>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id={"pills-corta"+this.props.codigo} role="tabpanel" aria-labelledby={"pills-corta-tab"+this.props.codigo}>
                  <div className="form-group">
                    <textarea className="form-control rounded-0" name="shortDescription" id="exampleFormControlTextarea1" rows="5" placeholder="Descripción corta" value={this.state.shortDescription} onChange={this.handleChange}></textarea>
                  </div>
                </div>
                <div className="tab-pane fade" id={"pills-larga"+this.props.codigo} role="tabpanel" aria-labelledby={"pills-larga-tab"+this.props.codigo}>
                  <div className="form-group">
                    <textarea className="form-control rounded-0" name="largeDescription" id="exampleFormControlTextarea1" rows="5" placeholder="Descripción larga" value={this.state.largeDescription} onChange={this.handleChange}></textarea>
                  </div>
                </div>
                <div className="tab-pane fade" id={"pills-img"+this.props.codigo} role="tabpanel" aria-labelledby={"pills-img-tab"+this.props.codigo}>
                  <div className="row">
                      {
                        this.state.images.map((file,idx)=>{
                          return (
                            <div className="col-4 mt-2 px-2" key={idx}>
                              <div className="borderImage" style={{position:"relative"}}>
                                <div className="icondelete">
                                  <button className="btnIcon" onClick={this.handleRemoveShareholder(idx)}><img src={iconDelete} /></button>
                                </div>
                                <div className="input-file-containers">  
                                  <input className="input-files" type="file" data-id={idx} name="image" onChange={this.handleChangeimg} />
                                  <label tabIndex="0" className="input-file-triggers"><img src={iconEdit}/></label>
                                </div>
                                <div className="content-Img">
                                {(file.image!=="")?<div className="file-return" style={{background:`url(${file.image})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain", width: "100%",height: "100%"}} alt="Noticias"></div>:""}
                                  <div className="selectStatus">
                                      <input type="radio" 
                                    data-id={idx}
                                    name="image" 
                                    value="img1" 
                                    checked={file.isMain} 
                                    onChange={this.handleChangeRadio} /> 
                                      <label>Principal</label>
                                    </div>
                                </div> 
                              </div>
                            </div>
                          )
                        })
                      }
                      <div className="iconadd">
                        <button className="btnIcon" onClick={this.handleAddShareholder}><img src={iconMore} /></button>
                      </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="modalBtn backgroung-btnBlack px-4" onClick={this.handleReset} data-dismiss="modal">Regresar</button>
          <button type="button" className="modalBtn backgroung-btnRed px-4" onClick={this.handleSubmit}>Guardar</button>
        </div>
      </div>
    )
  }
}
export default ModalNoticia;
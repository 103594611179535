import React, { Component } from 'react';
import fondo from '../../assets/cielo.png';
import pdfIcono from '../../assets/file rojo small.svg';
import file from '../../assets/Chemical dosing points.pdf'
import axios from 'axios';
import { URLHomeTest } from '../../auth/apis'
class Home extends Component{
  constructor(){
    super()
    this.state={
      collapse:false,
      arraySlide : []
    }
  }
  componentDidMount = async () =>{
    await this.getTestKits()
  }
  getTestKits = async ()=>{
    let dataNew = []

    await axios.get(URLHomeTest)
    .then(res=>{      
      res.data.testKits.map(element => {
        let tempData={
          name: element.name,
          description: element.description,
          urlImage: element.urlImage,
          urlFile: element.urlFile
        }
        dataNew.push(tempData)
      });
    })
   await this.setState({ arraySlide:dataNew })

  }
  render(){
    return(
      <section className="test-kit">
        <div className="title-section">
          <div>
            <img src={fondo} alt="fondo titulo"/>
          </div>
          <div className="titleContent">
              <h3>Test Kits</h3>
          </div>
        </div>
        <section className="content-section pt-5 pb-5">
          <div className="container">
            <div className="card-deck">
            {
              this.state.arraySlide.map(data=>{
                return(
                  <div className={"card mt-4"+((this.state.arraySlide.length % 3) === 0 ?" ":" size")}>
                    <div className="view overlay">
                      <img className="card-img-top" src={data.urlImage} alt="Card cap" />
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">{data.name}</h4>
                      <p className="card-text">{data.description}</p>
                      <a className="btn" href={data.urlFile} target="__blanck" download>
                        <img src={pdfIcono} alt="Icono de pdf" /> <span>Ficha Técnica</span>
                      </a>
                    </div>
                  </div>  
                )
              })
            }     
            </div>
            <div className="linkDown">
              <a  href={file} target="__blanck">
                <img src={pdfIcono} alt="Icono de pdf" /> 
                <span>Descargar Documento "Chemical Dosing Points"</span>
              </a>
            </div>
          </div>
        </section>
      </section>
    )
  }
}
export default Home;
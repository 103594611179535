import React, { Component } from 'react';
import Header from '../Components/Intranet/Header';
import ContentTab from '../Components/Intranet/ContentTab';
import withAuth from '../auth/withAuth';
import AuthService from './../auth/AuthService';
const Auth = new AuthService();

class Intranet extends Component{
  render(){
    return(
      <section>
        <Header history={this.props.history} auth={Auth.logout}/>
        <div className="container">
        <ContentTab />
        </div>
      </section>
    )
  }
}
export default withAuth(Intranet);
// export default Intranet
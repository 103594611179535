import React, { Component } from 'react';
import Header from '../Components/Header';
import Home from '../Components/Limites/home';
import Footer from '../Components/footer';
import './../App.css';

class Main extends Component{
  render(){
    return(
      <section>
        <Header name="limites"/>
        <Home />
        <Footer history={this.props.history}/>
      </section>
    )
  }
}
export default Main;
import React, { Component } from 'react';
import ModalProcudt from './Modals/ModalProduct';
import ModalNoticia from './Modals/ModalNoticia';
import ModalKit from './Modals/ModalKit';
import ModalLimites from './Modals/ModalLimites';

class Modal extends Component{
  render(){            
    return(
      <div className="modal fade" id={this.props.name+this.props.codigo} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
        <div className="modal-dialog" role="document">
            {
              (this.props.name==="product")?
              <ModalProcudt {...this.props}/>:
              (this.props.name==="noticia")?
              <ModalNoticia {...this.props}/>:
              (this.props.name==="kits")?
              <ModalKit {...this.props}/>:
              <ModalLimites {...this.props}/>
            }
        </div>
      </div>
    )
  }
}
export default Modal;
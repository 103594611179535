import React, { Component } from 'react';
import Modal from './Modal';
import { URLGetProducts,URLCategorias } from './../../auth/apis';
import iconoR from '../../assets/file rojo small.svg';
import iconN from '../../assets/file negro small.svg';
import axios from 'axios';
import ModalCreate from './ModalCreate'

class TableProduct extends Component{
  constructor(){
    super();
    this.auxData=null;
    this.state={
      categoria:'',
      selectedPost:null,
      data:[],
      newDATA:[],
      selects:[]
    }
  }

  componentWillMount = async () => {
    let newdata = []
    const headers = {
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }

   await axios.get(URLGetProducts,{ headers })
    .then(res =>{   
      res.data.products.map(element=>{                
        let tempData={
          id:element.id,
          name:element.name,
          description:element.description,
          ficha:element.urlDataSheet,
          hojaSeguridad: element.urlSafetyDataSheet,
          category:element.category,
          dateCreated:element.dateCreated,
          isActive:(element.isActive)?"Activo":"Inactivo"
        }
        newdata.push( tempData )
      })
    })

  await this.setState({ data:newdata, newDATA:newdata})
  await this.getCategoria();
  }
  getCategoria=()=>{
    const { selects } = this.state; 
    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    axios.get(URLCategorias,{headers})
    .then(data=>{      
      data.data.categories.forEach(element => {
        selects.push({
          id:element.id,
          name:element.name
        })
      });
      this.setState({ selects })
    })
  }

  getProduct = async () =>{
    let newdata = []
    const headers = {
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
   await axios.get(URLGetProducts,{ headers })
    .then(res =>{   
      res.data.products.map(element=>{
        let tempData={
          id:element.id,
          name:element.name,
          description:element.description,
          ficha:element.urlDataSheet,
          hojaSeguridad: element.urlSafetyDataSheet,
          category:element.category,
          dateCreated:element.dateCreated,
          isActive:(element.isActive)?"Activo":"Inactivo"
        }
        newdata.push( tempData )
      })
    })

   await this.setState({ data:newdata })
   await this.setState({ newDATA:this.state.data })
  }

  FilteredListProducts = (e) =>{
    const valueSearch=e.target.value
      const updateList = this.state.data.filter((item)=>{
        if(valueSearch.length >= 3 && valueSearch.trim() !== ""){
          return item.name.toLowerCase().search(valueSearch.toLowerCase()) !== -1;
        }else{
            return item
        }
      })      
      this.setState({
        newDATA:updateList
      })

  }
  createSelectItems=()=>{
    const { selects} = this.state;
    let items=[];
    selects.forEach(data=>{      
      items.push(<option key={data.id} value={data.name}>{data.name}</option>)
    })
    return items;
  }
  handleChangeFile=(e)=>{          
      this.setState({[e.target.name]: e.target.value})
      const updateList = this.state.data.filter((item)=>{
        if(e.target.value=== "todos"){
          return item
        }
        if(e.target.value === "select"){
          return false
        }
        return item.category.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
    })
    this.setState({
      newDATA:updateList
    })
  }

  render(){    

    return(
      <div className="tab-pane fade show active" id="product" role="tabpanel" aria-labelledby="product-tab">
      <div className="addbtn">
        <form className="form-inline md-form product form-sm mt-0 mt-0 mb-0 mr-4">
          <i className="fas fa-search" aria-hidden="true"></i>
          <input className="form-control form-control-sm ml-3 w-75 mb-0" type="text" placeholder="Buscar producto" aria-label="Search" onChange={this.FilteredListProducts}/>
        </form>
        <select name="categoria" value={this.state.categoria} onChange={this.handleChangeFile} className="browser-default custom-select tableSelect">
          <option value="select">Selecciona una categoría</option>
          <option value="todos">todos</option>
          {this.createSelectItems()}
        </select>    
        <button type="button" data-toggle="modal" data-target="#product-add" className="modalBtn backgroung-btnRed">Nuevo Producto</button>
        <ModalCreate name="product" codigo="-add" getData={()=>this.getProduct()} />
      </div>
      <div className="table-responsive">
      <table className="table table-striped table-background">
      <thead>
        <tr>
          <th className="px-2" scope="col">Código</th>
          <th className="px-2" scope="col">Categoría</th>
          <th className="px-1" scope="col">Producto</th>
          <th className="px-1" scope="col">Descripción</th>
          <th className="px-3" scope="col">Ficha Técnica</th>
          <th className="px-3" scope="col">Hoja de Seguridad</th>
          <th className="px-1" scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {
          this.state.newDATA.map((data,i)=> {
            return (
              <tr key={i}>
                <td className="px-2">{data.id}</td>
                <td className="px-2">{data.category}</td>
                <td className="px-1">{data.name}</td>
                <td className="px-1">{data.description}</td>
                <td className="px-4">
                  <a href={data.ficha} className="celdImage" target="__blanck"><img src={iconoR} alt="icono rojo"/></a>
                </td>
                <td className="px-4">
                  <a href={data.hojaSeguridad} className="celdImage" target="__blanck"><img src={iconN} alt="icono negro"/></a>
                </td>
                <td>
                  <button data-id={i} type="button" className="linkMore" data-toggle="modal" data-target={"#product"+data.id}>
                    Editar
                  </button>
                  <Modal 
                    name="product" 
                    codigo={data.id}
                    category={data.category}
                    producto={data.name}
                    description={data.description}
                    ficha={data.ficha}
                    seguridad={data.hojaSeguridad}
                    dateCreated={data.dateCreated}
                    isActive={data.isActive}
                    getData={()=>this.getProduct()}/>
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </table>    
    </div>
    </div>
    )
  }
}
export default TableProduct;
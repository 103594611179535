import React, { Component } from 'react';
import fondo from '../../assets/newAssets/banner web.png';
import navadan from '../../assets/navadan.png';
import chemo from '../../assets/chemo-copia.png';
import marine from '../../assets/marine-care-copia.png';
import marichem from '../../assets/marichem-copia.png';
import teco from '../../assets/teco-copia.png';
import blutec from '../../assets/blutec-copia.png'
import LOGO2 from '../../assets/Logos uniservice-06.svg';
import LOGO1 from '../../assets/Logos uniservice-05.svg';
import mundo from '../../assets/mundo logo.png'
class Home extends Component{
  render(){
    return(
      <section className="Acordion">
      <div className="title-section">
        <div>
          <img src={fondo} alt="fondo titulo"/>
        </div>
        <div className="titleContent">
            <h3>Historia</h3>
        </div>
      </div>
      <section className="content-section history">
        <div className="padding-40-100">

          <div className="historyContent" id="accordionExample">
            <div className="imgHistory" style={{backgroundImage:"../../assets/newAssets/Amazonas.jpg"}}>
            </div>
            <div className="infoHistory">
              <h3 className="titleMain">UNISERVICE LATINA SAC & NET CHEMICAL SAC</h3>
              <div>
                <p className="mb-3">Estamos abasteciendo en Perú de Químicos para uso Marino desde 1995. </p>
                <p className="mb-3">Empezamos como Representantes del Gruppo UNISERVICE <img className="worldIcon" src={mundo}/></p>
                <p className="mb-3">Actualmente representamos, además, a las principales marcas del mundo que 
                operan en nuestro sector:</p>
                <div className="mt-4">
                <div className="row mx-0">
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 marca px-1">
                    <div className=".lat-left">
                      <img src={LOGO1} className="widthUniservice" alt="Teco logo"/>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 marca px-1">
                    <div className="norm">
                      <img src={LOGO2} className="widthUniLatina" alt="Teco logo"/>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 marca px-1">
                    <div className="norm">
                      <img src={navadan} className="widthNavadan" alt="Navadan logo"/>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 marca px-1">
                    <div className="lat-lg-left lat-xl-center">
                      <img src={chemo} className="widthChemo" alt="Chemo logo"/>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 marca px-1">
                    <div className="lat-lg-center lat-xl-left">
                      <img src={marichem} className="widthMarichem" alt="Marichem logo"/>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 marca px-1">
                    <div className="lat-lg-left lat-xl-center">
                      <img src={blutec} className="widthBlutec" alt="Blutec logo"/>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 marca px-1">
                    <div className="lat-lg-left lat-xl-center">
                      <img src={marine} className="widthMarin" alt="Marine Care logo"/>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 marca px-1">
                    <div className="norm">
                      <img src={teco} className="widthTeco" alt="Teco logo"/>
                    </div>
                  </div>
                </div>
              </div>
                <p className="mb-3">
                Nos impusimos dar el mismo nivel de abastecimiento que se da en los principales puertos del mundo 
                en calidad, cantidad, precio y rapidez.
                </p>
                <p className="mb-3">Somos parte del esfuerzo peruano por lograr una mejor Marina Mercante.</p>
                <p>...se está logrando! </p>
              </div>
              <div className="mt-4">
                <h4>Federico Woodman Gotuzzo</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    )
  }
}
export default Home;
import React, { Component } from 'react';

class SlideNav extends Component{
  render(){
    return(
      <div className={this.props.slideClass} id='menu'>
        <div className="content-nav-lateral">
          <ul className="nav-lateral">
            {this.props.children}
          </ul>
        </div>
      </div>
    )
  }
}
export default SlideNav;
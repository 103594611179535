import decode from 'jwt-decode';
import axios from 'axios';
import { URLAuthLogin,URLToken } from './apis'

export default class AuthService {
    constructor() {
        this.login = this.login.bind(this)
    }
    
    login(email, password) {        
        return axios.post(URLAuthLogin, {
            email:email,
            password:password
        })
        .then(res => {         
            localStorage.setItem('token', res.data.accessToken)
            this.setToken(res.data.refreshToken)
            return Promise.resolve(res);
            // return  axios.post(URLToken,{refreshToken:res.data.refreshToken})
            // .then(res2=>{
            //     localStorage.setItem('token', res.data.accessToken)
            //     this.setToken(res.data.refreshToken)
            //     return Promise.resolve(res2);

            // })
        }).catch((ex)=>{
            console.log(ex);
        })
    }

    loggedIn() {
        const token = localStorage.getItem('token');
        const tokenR = this.getToken()
        return !!token && !this.isTokenExpired(tokenR)
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            }
            else
                return false; 
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        localStorage.setItem('id_token', idToken)
    }

    getToken() {
        return localStorage.getItem('token')
    }

    logout() {
        localStorage.removeItem('token');
    }

    getDataUser(data){
        return data
    }
    getProfile() {
        return decode(this.getToken());
    }

}
import React, { Component } from 'react';
import axios from 'axios';
import iconPdf from '../../../assets/icons8-pdf-2-filled.svg';
import { URLPutKits } from './../../../auth/apis';
class ModalKit extends Component{
  constructor(props){
    super(props);
    this.state={
      codigo:"",
      nombre:"",
      descripcion:"",
      image:" ",
      extimage:"",
      file:"",
      extfile:"",
      dateCreated:"",
      estatus:""
    }
  }
  componentDidMount = async() =>{
    await this.setState({
      codigo:this.props.codigo,
      nombre:this.props.nombre,
      descripcion:this.props.description,
      extimage:this.props.urlImage,
      extfile:this.props.urlFile,
      dateCreated:this.props.dateCreated,
      estatus:(this.props.isActive==="Activo")?"Activo":"Inactivo",
    })
  }
  readFile=(data)=>{
      var request = new XMLHttpRequest();
      request.open('GET', data, true);
      request.responseType = 'blob';
      request.onload = function() {
          var reader = new FileReader();
          reader.readAsDataURL(request.response);
          reader.onload =  function(e){
              console.log('DataURL:', e.target.result);
          };
      };
      request.send();
  }
  readImage=(data)=>{
    var request = new XMLHttpRequest();
      request.open('GET', data, true);
      request.responseType = 'blob';
      request.onload = function() {
          var reader = new FileReader();
          reader.readAsDataURL(request.response);
          reader.onload =  function(e){
              console.log('DataURL:', e.target.result);
          };
      };
      request.send();
  }
  handleChangeFile=(e)=>{        
    if(e.target.name==="file"){
      var reader = new FileReader();
      reader.onload = ()=>this.setState({extfile: reader.result})
      reader.readAsDataURL(e.target.files[0]);
      this.setState({file:e.target.files[0].name})
    } else{
      this.setState({[e.target.name]: e.target.value})
    }
  }
  handleChangeFileS=(e)=>{    
    this.setState({image:e.target.files[0].name})
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = ()=>{this.setState({extimage:reader.result})}
  }

  getFileExtension1=(filename) =>{
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }
  handleReset = ()=>{
    this.setState({
      codigo:this.props.codigo,
      nombre:this.props.nombre,
      descripcion:this.props.description,
      image:" ",
      extimage:this.props.urlImage,
      file:"",
      extfile:this.props.urlFile,
      dateCreated:this.props.dateCreated,
      estatus:(this.props.isActive)?"Activo":"Inactivo",
    })
  }
  handleChangeStatus=(val)=>{
    this.setState({
      estatus:val,
    })
  }
  handleSubmit = () =>{
    const { codigo,nombre,descripcion,extimage,extfile,image,file,estatus } = this.state;
    const { getData } = this.props;
    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    const body={
      "name":(nombre === this.props.producto)?null:nombre,
      "description":(descripcion === this.props.descripcion)?null:descripcion,
      "isActive":(estatus==="Inactivo")?false:true,
      "image":(extimage===this.props.urlImage)?extimage:image,
      "file":(extfile===this.props.urlFile)?extfile:file,
    }    

    axios.put(URLPutKits(codigo),body,{headers})
    .then(res=>{
      console.log(res);
      if(res.status===200){
        alert("Se guardo correctamente los datos")
        getData()

      }else{
        alert("Problemas con servidor, constacte a su proveedor")
      }
    })
  }
  render(){   
    const { nombre,descripcion }=this.state    
    const extFile=this.getFileExtension1(this.state.extfile) || '';
    const extImage=this.getFileExtension1(this.state.extimage) || ""    ;
    const urlImage=this.state.extimage.split("/")
    const urlFile=this.state.extfile.split("/")  
      
    return(
      <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Editar Test Kit</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-6">
                <label className="label" htmlFor="form2">Nombre de Test Kit</label>
                <div className="md-form">
                  <input type="text" id="form1" name="nombre" className="form-control" value={nombre} onChange={this.handleChangeFile}/>
                </div>
              </div>
              <div className="col-6">
              <label className="label" htmlFor="form1">Estado: </label>
                <div className="mt-3 d-flex">
                  <div className="custom-control custom-radio mr-4">
                    <input type="radio" className="custom-control-input" id={"idKitActivo"+this.props.codigo}  value="Activo" checked={this.state.estatus === "Activo"} onChange={(e)=>{this.handleChangeStatus(e.target.value)}}/> 
                    <label className="custom-control-label" htmlFor={"idKitActivo"+this.props.codigo}>Activo</label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input type="radio" className="custom-control-input" id={"idKitInactivo"+this.props.codigo} value="Inactivo" checked={this.state.estatus === "Inactivo"} onChange={(e)=>{this.handleChangeStatus(e.target.value)}}/> 
                    <label className="custom-control-label" htmlFor={"idKitInactivo"+this.props.codigo}>Inactivo</label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <label className="label" htmlFor="form2">Descripción:</label>
                <textarea type="text" id="exampleForm2" name="descripcion" className="form-control" placeholder="Descripcion" rows="3" value={descripcion} onChange={this.handleChangeFile}/>
              </div>
              <div className="col-12">
                <div className="d-flex px-15 justify-content-between info-product">
                  <p className="mb-0 align-self-center namesFichas">Imagen</p>
                    {
                      
                      !(/\.(jpg|png|gif|jpeg)$/i).test(extImage)?
                      <p className="iconopdf mr-0">
                        <img src={this.state.extimage} alt="imageFondo"/>
                        <span>{urlImage[urlImage.length-1]}</span>
                      </p>
                      :<p className="iconopdf"><span>No es una imagen</span></p>
                    }
                  <div className="input-file-container">  
                    <input className="input-file" name="image" id="my-file" type="file" onChange={this.handleChangeFileS}/>
                    <label tabIndex="0" htmlFor="my-file" className="input-file-trigger">Reemplazar archivo</label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex px-15 justify-content-between info-product">
                  <p className="mb-0 align-self-center namesFichas">Ficha Técnica</p>
                    {(extFile==="pdf" || this.state.extFile!=="")?
                    <p className="iconopdf mr-0">
                    <img src={iconPdf} alt="imageFondo"/>
                    <span>{urlFile[urlFile.length-1]}</span>
                  </p>
                  :<span>No es un archivo .pdf</span>
                    }
                  <div className="input-file-container">  
                    <input className="input-file" name="file" id="my-file" type="file" onChange={this.handleChangeFile}/>
                    <label tabIndex="0" htmlFor="my-file" className="input-file-trigger">Reemplazar archivo</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="modalBtn backgroung-btnBlack px-4" onClick={this.handleReset} data-dismiss="modal">Regresar</button>
            <button type="button" className="modalBtn backgroung-btnRed px-4" onClick={this.handleSubmit}>Guardar</button>
          </div>
      </div>
    )
  }
}
export default ModalKit;
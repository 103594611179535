import React, { Component } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/footer';
import Noticias from './../Components/Noticias/AllNoticias';
class AllNoticias extends Component{
  render(){
    return(
      <div>
        <Header />
        <Noticias history={this.props.history}/>
        <Footer history={this.props.history}/>
      </div>
    )
  }
}
export default AllNoticias 
import React, { Component } from 'react';
import icondRojo from '../../assets/file rojo small.svg';
import icondNegro from '../../assets/file negro small.svg';
import fondo from '../../assets/cielo.png';
import axios from 'axios';
import Acordion from './Acordion';
import List from './List'
import { URLHomeProducts } from '../../auth/apis'
class Home extends Component{
  constructor(){
    super()
    this.state={
      arraySlide : [],
      val:"",
      shown:[]
    }
  }
  componentDidMount = async() =>{
    await this.getProducts()
    let Data=[]
    this.state.arraySlide.map((data,i)=>{
      let value=false
      Data.push(value)
    })
    this.setState({shown:Data})
  }
  
  getProducts = async () =>{
    let dataNew = []
    await axios.get(URLHomeProducts)
    .then(res=>{
      console.log(res);
      res.data.categories.map(element =>{
        let tempData= {
          category:element.category,
          products:element.products
        }
        return dataNew.push(tempData)}
      );
    })
    await this.setState({ arraySlide:dataNew })
  }

  handleChange=(e)=>{
    this.setState({val:e.target.value})
  }
  toggle=(number)=>{
    const prevState = this.state.shown;
    const state = prevState.map((x,index) => number === index ? !x : false);

    this.setState({
      shown: state,
    });

  }
  render(){
    const acordionCard=[];    
    const searchTerm=this.state.val;
    this.state.arraySlide.forEach((element,i)=>{            
      if(searchTerm === "" || searchTerm.length < 3 || searchTerm.trim() === "" ){
        acordionCard.push(<Acordion id={i} category={element.category} product={element.products} toggle={this.toggle} show={this.state.shown}/>)
      }else{
        element.products.forEach((prod,key)=>{
            if(prod.name.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1)
            return
          acordionCard.push(<List name={prod.name} description={prod.description} urlDataSheet={prod.urlDataSheet} urlSafetyDataSheet={prod.urlSafetyDataSheet} id={key}/>)
        })
      }
    })

    return(
      <section className="Acordion">
        <div className="title-section">
          <div>
            <img src={fondo} alt="fondo titulo"/>
          </div>
          <div className="titleContent">
              <h3>Químicos</h3>
          </div>
        </div>
        <section className="content-section padding-5">
          <div className="container">
            <div className="mt-4">
              <form className="form-inline md-form product form-sm mt-0 mt-0 mb-0 mr-4">
                <input className="form-control form-control-sm w-75 mb-0" type="text" placeholder="Buscar producto" value={this.state.val} onChange={this.handleChange}/>
              </form>
            </div>
            <div className="accordion" id="accordionExample">
            {acordionCard}
            </div>
          </div>
        </section>
      </section>
    )
  }
}
export default Home;
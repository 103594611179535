import React from 'react';
import { Switch,
          Route,
          Redirect } from 'react-router-dom';
import './App.css';
import './style/Header.css';
import './style/General.css';
import './style/Section-Welcome.css';
import './style/Section-Noticias.css';
import './style/footer.css';
import './style/Acordion.css';
import './style/OptionsNav.css';
import './style/Intranet.css';
import Main from './Content/Main';
import Quimicos from './Content/Quimicos';
import Limites from './Content/limites';
import Kits from './Content/Kits'
import Noticias from './Content/AllNoticas'
import IDNoticias from './Content/IDNoticias';
import Intranet from './Content/Intranet';
import Historia from './Content/Historia';

const AppRoutes = () => (    
    <Switch>
        <Route exact path = "/" component={Main}/>
        <Route exact path = "/Noticias" component={Noticias}/>
        <Route exact path = "/Historia" component={Historia}/>
        <Route exact path = "/Catalogos-Quimicos" component={Quimicos}/>
        <Route exact path = "/Test-kits" component={Kits}/>
        <Route exact path = "/Limites-Diarios" component={Limites}/>
        <Route exact path = "/Noticias-Id/:id" component={IDNoticias}/>
        <Route exact path = "/Intranet" component={Intranet}/> 
        <Redirect to= "/" />
    </Switch>
  )
export default AppRoutes;
import React, { Component } from 'react';
import fondo from '../../assets/cielo.png';
import iconPdf from '../../assets/icons8-pdf-2-filled.svg';
import iconExcel from '../../assets/icons8-xls-filled.svg';
// import filePdf from '../../assets/DJBÁSICO FEBRERO 2019.pdf'
import axios from 'axios';
import { URLHomeLimits } from '../../auth/apis'
class Home extends Component{
  constructor(){
    super()
    this.state={
      arraySlide: [],
      shown:[]
    }
  }
  componentDidMount = async () =>{
    await this.getLimits()

    let Data=[]
    this.state.arraySlide.map((data,i)=>{
      let value=false
      Data.push(value)
    })
    this.setState({shown:Data})
  }
  toggle=(number)=>{
    const prevState = this.state.shown;
    const state = prevState.map((x,index) => number === index ? !x : false);

    this.setState({
      shown: state,
    });

  }
  getLimits = async () =>{
    let newData=[]
    await axios.get(URLHomeLimits)
    .then(res=>{      
      res.data.categories.map(element =>{
        let tempData={
          category:element.category,
          limitsAndDiaries:element.limitsAndDiaries
        }
        newData.push(tempData)
      }
      );
    })
    await this.setState({ arraySlide:newData })
  }

  getFileExtension1=(filename) =>{
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }
  
  render(){
    console.log(this.state.arraySlide);
    
    return(
      <section className="Acordion">
        <div className="title-section">
          <div>
            <img src={fondo} alt="fondo titulo"/>
          </div>
          <div className="titleContent">
              <h3>Límites y Diarios</h3>
          </div>
        </div>
        <section className="content-section padding-5">
          <div className="container">
          <div className="accordion" id="accordionExample">
            {
              this.state.arraySlide.map((data,key)=>{
                return (
                  <div className="card mt-1">
                    <div className="card-header" id={"heading"+key}>
                        <button className="btn btn-link"   type="button" data-toggle="collapse" data-target={"#collapse"+key} aria-expanded="true" aria-controls={"collapse"+key} onClick={()=>this.toggle(key)}>
                        <i className={"fas"+(this.state.shown[key] ? " fa-minus-circle" : " fa-plus-circle")}></i><span>{data.category}</span>
                        </button>
                    </div>
                    <div id={"collapse"+key} className="collapse" aria-labelledby={"heading"+key} data-parent="#accordionExample">
                      <div className="card-body">
                        <div className="row content-enlace mx-0">
                          {
                            data.limitsAndDiaries.map((element,key)=>{   
                              console.log(element);
                                                         
                              const extFile = this.getFileExtension1(element.urlFile)                                                            
                              return (
                                <div className="col-5 col-sm-4 col-md-3 col-lg-3 px-2" key={key}>
                                  <a href={element.urlFile} className="enlaceLimit" target="__blanck">
                                    <img src={extFile === "pdf"?iconPdf:iconExcel} alt="Iconos"/>
                                    <span>{element.fileName}</span>
                                  </a>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            </div>
          </div>
        </section>
      </section>
    )
  }
}
export default Home;
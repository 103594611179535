import React, { Component } from 'react';
import {MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact'
import pdfIconoRojo from '../../../assets/file rojo small.svg';
import pdfIconoNegro from '../../../assets/file negro small.svg';
import {URLPutProducts} from '../../../auth/apis';
import axios from 'axios'
class ModalProduct extends Component{
  constructor(){
    super();
    this.state={
      id:"",
      category:"",
      name:"",
      extensionseguridad:"",
      description:"",
      ficha:"",
      extensionficha:"",
      hojaSeguridad:"",
      estatus:""
    }
  }
  componentWillReceiveProps  = async (nextProps) =>{      
    await this.setState({
      id:nextProps.codigo,
      category:nextProps.category,
      name:nextProps.producto,
      description:nextProps.description,
      ficha:nextProps.ficha,
      hojaSeguridad:nextProps.seguridad,
      estatus:(nextProps.isActive==="Activo")?"Activo":"Inactivo",
    })
  }
  handleChange=(e)=>{
    this.setState({[e.target.name]: e.target.value})
  }
  handleChangeFile= async (e)=>{    
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = ()=>this.setState({ficha: reader.result})
    this.setState({extensionficha:e.target.files[0].name})
  }
  handleChangeFileS = async (e)=>{        
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = ()=>{ this.setState({hojaSeguridad:reader.result})}
    this.setState({extensionseguridad:e.target.files[0].name})
  }

  getFileExtension1=(filename) =>{
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }
  // handleReset = async (nextProps)=>{
  //   await this.setState({
  //     id:this.props.codigo,
  //     category:this.props.category,
  //     name:this.props.producto,
  //     description:this.props.description,
  //     ficha:this.props.ficha,
  //     hojaSeguridad:this.props.seguridad,
  //     estatus:(this.props.isActive==="Activo")?"Activo":"Inactivo",
  //   })
  // }
  handleChangeStatus=(val)=>{    
    this.setState({
      estatus:val,
    })
  }
  handleSubmit= async (e)=>{
    
    const { getData } = this.props;
    const { id,name,description,ficha,hojaSeguridad,estatus } = this.state;
    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    } 
    const body={
      "name":name === this.props.producto?null:name,
      "description":description === this.props.description?null:description,
      "isActive":(estatus==="Inactivo")?false:true,
      "dataSheet":ficha === this.props.ficha?ficha:ficha,
      "safetyDataSheet":hojaSeguridad === this.props.seguridad?hojaSeguridad:hojaSeguridad,
    }                    
    await axios.put(URLPutProducts(id),body,{headers})
    .then(res=>{
      console.log(res);
      if(res.status===200){
        alert("Se guardo correctamente los datos")
        getData()
        e.stopPropagation()
      }else{
        alert("Problemas con servidor, constacte a su proveedor")
      }
    })  
  }

  render(){             
    const { category,name,description }=this.state
    const extFicha=this.getFileExtension1(this.state.ficha)|| ''
    const extSeguridad=this.getFileExtension1(this.state.hojaSeguridad)|| ''
    const urlFicha=this.state.ficha.split("/")
    const urlSeguridad=this.state.hojaSeguridad.split("/")     
    return(
      <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Editar Producto</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <label className="label" htmlFor="form1">Categoría</label>
                <div className="md-form">
                  <input type="text" id="form1" className="form-control" value={category} onChange={this.handleChange}/>
                </div>
              </div>
              <div className="col-6">
                <label className="label" htmlFor="form2">Producto</label>
                <div className="md-form">
                  <input type="text" id="form2" name="name" className="form-control" value={name} onChange={this.handleChange} />
                </div>
              </div>
              <div className="col-6">
              <label className="label" htmlFor="form1">Estado: </label>
                <div className="mt-3 d-flex">
                  <div className="custom-control custom-radio mr-4">
                    <input type="radio" className="custom-control-input" id={"idProdActivo"+this.props.codigo}  value="Activo" checked={this.state.estatus === "Activo"} onChange={(e)=>{this.handleChangeStatus(e.target.value)}}/> 
                    <label className="custom-control-label" htmlFor={"idProdActivo"+this.props.codigo}>Activo</label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input type="radio" className="custom-control-input" id={"idProdInactivo"+this.props.codigo} value="Inactivo" checked={this.state.estatus === "Inactivo"} onChange={(e)=>{this.handleChangeStatus(e.target.value)}}/> 
                    <label className="custom-control-label" htmlFor={"idProdInactivo"+this.props.codigo}>Inactivo</label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <label className="label" htmlFor="form3">Descripción del producto</label>
                <div className="md-form">
                  <textarea type="text" id="form3" name="description" rows="3" className="form-control" value={description} onChange={this.handleChange}/>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex px-15 justify-content-between info-product">
                  <p className="mb-0 align-self-center namesFichas">Ficha Técnica</p>
                  {(extFicha==="pdf" || this.state.ficha!=="")?
                    <p className="iconopdf productEdit">
                    <img src={pdfIconoRojo} alt="imageFondo"/>
                    {this.state.extensionficha !== ""?
                    <span>{this.state.extensionficha}</span>:
                    <span>{urlFicha[urlFicha.length-1]}</span>}
                  </p>
                  :<span>No es un archivo .pdf</span>
                    }
                  <div className="input-file-container">  
                    <input className="input-file" name="ficha" id="my-file" type="file" onChange={this.handleChangeFile}/>
                    <label tabIndex="0" htmlFor="my-file" className="input-file-trigger">Reemplazar archivo</label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex px-15 justify-content-between info-product">
                  <p className="mb-0 align-self-center namesFichas">Hoja de Seguridad</p>
                  {(extSeguridad==="pdf" || this.state.seguridad!=="")?
                    <p className="iconopdf productEdit">
                    <img src={pdfIconoNegro} alt="imageFondo"/>
                    {this.state.extensionseguridad !==""?<span>{this.state.extensionseguridad}</span>:
                     <span>{urlSeguridad[urlSeguridad.length-1]}</span>
                  }
                  </p>
                  :<span>No es un archivo .pdf</span>
                    }

                  <div className="input-file-container">  
                    <input className="input-file" id="my-file" name="seguridad" type="file" onChange={this.handleChangeFileS}/>
                    <label tabIndex="0" htmlFor="my-file" className="input-file-trigger">Reemplazar archivo</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="modalBtn backgroung-btnBlack px-4" onClick={this.handleReset} data-dismiss="modal">Regresar</button>
            <button type="button" className="modalBtn backgroung-btnRed px-4" onClick={this.handleSubmit}>Guardar</button>
          </div>
      </div>
    )
  }
}
export default ModalProduct;
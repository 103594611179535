const URLDomain = "https://api.uniservicelatina.com"
// const URLDomain = "http://192.168.1.60:3000"

// -----------------------HOME-PAGE--------------------
export const URLAuthLogin = `${URLDomain}/auth/login`;
export const URLToken = `${URLDomain}/auth/token`
export const URLHomeLimits = `${URLDomain}/limits-diaries`;
export const URLNoticiasRecientes= `${URLDomain}/news/recents`;
export const URLHomeNoticias= `${URLDomain}/news`;
export const URLHomeProducts= `${URLDomain}/products`;
export const URLHomeTest= `${URLDomain}/test-kits`;

export function URLHomeNoticiasId(id){
  return `${URLDomain}/news/${id}`
}

// ---------- INTRANET ------------------------
// -----GET 
export const URLCategorias = `${URLDomain}/intranet/categories`;
export const URLGetLimits = `${URLDomain}/intranet/limits-diaries`;
export const URLGetNoticias = `${URLDomain}/intranet/news`;
export const URLGetProducts = `${URLDomain}/intranet/products`;
export const URLGetKits = `${URLDomain}/intranet/test-kits`;


// -----POST
export const URLProducts=`${URLDomain}/intranet/products`;
export const URLNoticias = `${URLDomain}/intranet/news`;
export const URLKits = `${URLDomain}/intranet/test-kits`;
export const URLLimits = `${URLDomain}/intranet/limits-diaries`;

// -----PUT
export function URLPutProducts(id){
  return `${URLDomain}/intranet/products/${id}`
}
export function URLPutNoticias(id){
  return `${URLDomain}/intranet/news/${id}`
}
export function URLPutKits(id){
  return `${URLDomain}/intranet/test-kits/${id}`
}
export function URLPutLimits(id){
  return `${URLDomain}/intranet/limits-diaries/${id}`
}
export function URLGenericTypesId(id){
  return `${URLDomain}/intranet/products/${id}`
}

// -----DELETE
export function URLDeleteLimits(id){
  return `${URLDomain}/intranet/limits-diaries/files/${id}`
}
export function URLDeleteImages(id){
  return `${URLDomain}/intranet/news/images/${id}`
}
import React, { Component } from 'react';
import Modal from './Modal';
import ModalCreate from './ModalCreate'
import { URLGetNoticias } from './../../auth/apis';
import axios from 'axios';

class TableNoticia extends Component{
  constructor(){
    super()
    this.state={
      valueSelect:"todos",
      data:[],
      newData:[]
    }
  }
  componentWillMount = async () =>{
    let newdata = []

    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    await axios.get(URLGetNoticias,{headers})
    .then(res=>{     
      console.log(res);
 
      res.data.news.map(element => {    
                    
        let tempData= {
          id:element.id,
          title:element.title,
          shortDescription:element.shortDescription,
          longDescription:element.longDescription,
          dateCreated:element.dateCreated,
          dateLasUpdated:element.dateLasUpdated,
          isActive:(element.isActive)?"Activo":"Inactivo",
          images:element.images
        }
        newdata.push(tempData)
      });
    })
    await this.setState({ data:newdata })
    await this.setState({newData:this.state.data})
  }
  getNoticia = async () =>{
    let newdata = []

    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    await axios.get(URLGetNoticias,{headers})
    .then(res=>{      
      console.log(res);
      
      res.data.news.map(element => {    
                    
        let tempData= {
          id:element.id,
          title:element.title,
          shortDescription:element.shortDescription,
          longDescription:element.longDescription,
          dateCreated:element.dateCreated,
          dateLasUpdated:element.dateLasUpdated,
          isActive:(element.isActive)?"Activo":"Inactivo",
          images:element.images
        }
        newdata.push(tempData)
      });
    })
    await this.setState({ data:newdata })
    await this.setState({newData:this.state.data})

  }
  componentDidMount(){
  }
  handleChangeFile=(e)=>{          
    const updateList = this.state.data.filter((item)=>{
      if(e.target.value=== "todos"){
        return item
      }
      return item.isActive.search(e.target.value) !== -1;
  })
  this.setState({
    valueSelect:e.atger.value,
    newData:updateList
  })
}
  render(){
    return(
      <div className="tab-pane fade" id="noticia" role="tabpanel" aria-labelledby="noticia-tab">
        <div className="addbtn">
          <select className="browser-default custom-select noticia" value={this.state.valueSelect} onChange={this.handleChangeFile}>
            <option value="todas" selected>Todas</option>
            <option value="Activo">Activas</option>
            <option value="Inactivo">Inactivas</option>
          </select>
          <button type="button" data-toggle="modal" data-target="#noticia-add" className="modalBtn backgroung-btnRed">Nueva Noticia</button>
          <ModalCreate name="noticia" codigo="-add" getData={()=>this.getNoticia()}/>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-background">
            <thead>
              <tr>
                <th className="px-2" scope="col">Código</th>
                <th className="px-2" scope="col">Título de Noticia</th>
                <th scope="col">Descripción corta</th>
                <th scope="col">Estado</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
            {
              this.state.newData.map((data,key)=>              
                <tr key={key}>
                  <td className="px-2">{data.id}</td>
                  <td className="px-2">{data.title}</td>
                  <td>{data.shortDescription}</td>
                  <td>{((data.isActive === "Activo") ? "Activo": "Inactivo")}</td>
                  <td>                
                    <button className="linkMore" data-toggle="modal" data-target={"#noticia"+data.id}>
                      Editar
                    </button>
                    <Modal 
                      name="noticia" 
                      codigo={data.id}
                      titulo={data.title}
                      shortDescription={data.shortDescription}
                      longDescription={data.longDescription}
                      dateCreated={data.dateCreated}
                      isActive={data.isActive}
                      images={data.images}
                      getData={()=>this.getNoticia()}/>
                  </td>
                </tr>
              )
            }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
export default TableNoticia;
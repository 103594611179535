import React, { Component } from 'react';
import {URLPutNoticias,URLDeleteImages} from '../../../auth/apis';
import iconMore from '../../../assets/icons8-más-filled-1.svg';
import iconDelete from '../../../assets/icons8-delete (1).svg';
import iconEdit from '../../../assets/icons8-edit.svg';
import axios from 'axios'

class ModalNoticia extends Component{
  constructor(props){
    super(props)
    this.state={
      codigo:"",
      title:"",
      shortDescription:"",
      largeDescription:"",
      estatus:"",
      images:[]
    }
    this.handleChangeRadioNoticia = this.handleChangeRadioNoticia.bind(this)
  }
  componentDidMount = async() =>{
    const { images } = this.state
    await this.setState({
      codigo:this.props.codigo,
      title:this.props.titulo,
      shortDescription:this.props.shortDescription,
      largeDescription:this.props.longDescription,
      estatus:(this.props.isActive==="Activo")?"Activo":"Inactivo",
    })
    this.props.images.forEach(data=>{      
      images.push({
        id:data.id,
        image:data.urlImage,
        isMain:data.isMain,
      })
    })
    await this.setState({images})
  }

  handleChange = (e) =>{
    this.setState({[e.target.name]:e.target.value})
  }
  handleChangeimg=(e)=>{
    let id=e.target.dataset.id;
    let name=e.target.name    
    let aux = e.target.files[0].name.split(".")
    if(aux[aux.length-1] !== "pdf"){
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e)=>{
        let images = [...this.state.images]        
        images[id][name]=reader.result;      
        this.setState({ images })
      }
    }else{
      alert("Ingresa una imagen")
    }

  }
  handleChangeStatusNoticias = ( val ) =>{
    this.setState({
      estatus:val,
    })
  }
  handleAddShareholder = () => {
    this.setState((prevState) =>({
      images:[...prevState.images, {image:"",isMain:false}],
    }));
  };
  handleDelete = (idx,id) => async (e) => {
    this.setState({
      images: this.state.images.filter((s, sidx) => idx !== sidx)
    });
    if(id === undefined){
      return false
    }
    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    await axios.delete(URLDeleteImages(id),{headers})
    .then(res=>{
      console.log(res);
    })
  };

  handleChangeRadioNoticia = async(e)=>{    
    let id=e.target.dataset.id;
    let images = [...this.state.images];    
    for (let i = 0; i < images.length; i++) {      
      if(Number(id)===i){
        images[i].isMain=!Number(e.target.name);
      }else{
        images[i].isMain=!!Number(e.target.name);
      }
    }
    await this.setState({images})
  }

  handleReset = async ()=>{
    const { getData } = this.props;
    getData()
    await this.setState({
      codigo:this.props.codigo,
      title:this.props.titulo,
      shortDescription:this.props.shortDescription,
      largeDescription:this.props.longDescription,
      estatus:(this.props.isActive)?"Activo":"Inactivo",
      images:this.state.images
    })
  }
  handleSubmit = () =>{
    const { codigo,title,shortDescription,largeDescription,images,estatus } = this.state;
    const { getData } = this.props;
    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    const body={
      "title":(title===this.props.titulo)?null:title,
      "shortDescription":(shortDescription===this.props.shortDescription)?null:shortDescription,
      "isActive":(estatus==="Inactivo")?false:true,
      "longDescription":(largeDescription===this.props.longDescription)?null:largeDescription,
      "images":images
    }  
    axios.put(URLPutNoticias(codigo),body,{headers})
    .then(res=>{
      if(res.status===200){
        alert("Se guardo correctamente los datos")
        getData()
      }else{
        alert("Problemas con servidor, constacte a su proveedor")
      }
    })
  }
  
  render(){        
    return(
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Editar Noticia</h5>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-6">
              <label className="label" htmlFor="form1">Titulo de Noticia: </label>
              <div className="md-form">
                <input type="text" id="form1" className="form-control" name="title" value={this.state.title} onChange={ this.handleChange} />
              </div>
            </div>
            <div className="col-6">
              <label className="label" htmlFor="form1">Estado: </label>
              <div className="mt-3 d-flex">
                <div className="custom-control custom-radio mr-4">
                    <input type="radio" className="custom-control-input" id={"idNotActivo"+this.props.codigo}  value="Activo" checked={this.state.estatus === "Activo"} onChange={(e)=>{this.handleChangeStatusNoticias(e.target.value)}}/> 
                    <label className="custom-control-label" htmlFor={"idNotActivo"+this.props.codigo}>Activo</label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input type="radio" className="custom-control-input" id={"idNotInactivo"+this.props.codigo} value="Inactivo" checked={this.state.estatus === "Inactivo"} onChange={(e)=>{this.handleChangeStatusNoticias(e.target.value)}}/> 
                    <label className="custom-control-label" htmlFor={"idNotInactivo"+this.props.codigo}>Inactivo</label>
                  </div>
              </div>
            </div>
            <div className="col-12">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id={"pills-corta-tab"+this.state.codigo} data-toggle="pill" href={"#pills-corta"+this.state.codigo} role="tab" aria-controls={"pills-corta"+this.state.codigo} aria-selected="true">Descripción corta</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id={"pills-larga-tab"+this.state.codigo} data-toggle="pill" href={"#pills-larga"+this.state.codigo} role="tab" aria-controls={"pills-larga"+this.state.codigo} aria-selected="false">Descripción larga</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id={"pills-img-tab"+this.state.codigo} data-toggle="pill" href={"#pills-img"+this.state.codigo} role="tab" aria-controls={"pills-img"+this.state.codigo} aria-selected="false">Imágenes</a>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id={"pills-corta"+this.state.codigo} role="tabpanel" aria-labelledby={"pills-corta-tab"+this.state.codigo}>
                  <div className="form-group">
                    <textarea className="form-control rounded-0" id="exampleFormControlTextarea1" name="shortDescription" rows="5" placeholder="Descripción corta" value={this.state.shortDescription} onChange={ this.handleChange }></textarea>
                  </div>
                </div>
                <div className="tab-pane fade" id={"pills-larga"+this.state.codigo} role="tabpanel" aria-labelledby={"pills-larga-tab"+this.state.codigo}>
                  <div className="form-group">
                    <textarea className="form-control rounded-0" id="exampleFormControlTextarea1" name="largeDescription" rows="5" placeholder="Descripción larga" value={this.state.largeDescription} onChange={ this.handleChange }></textarea>
                  </div>
                </div>
                <div className="tab-pane fade" id={"pills-img"+this.props.codigo} role="tabpanel" aria-labelledby={"pills-img-tab"+this.props.codigo}>
                  <div className="container">
                    <div className="row">
                    {
                      this.state.images.map((data,key)=>{                                                     
                        return (
                          <div className="col-4 mt-2 px-2"  key={key}>
                            <div className="borderImage modalNoticia" style={{position:"relative"}}>
                              <div className="icondelete">
                                <button className="btnIcon" onClick={this.handleDelete(key,data.id)}><img src={iconDelete}/></button>
                              </div>
                              <div className="input-file-containers">  
                                <input className="input-files" type="file" data-id={key} name="image" onChange={this.handleChangeimg} />
                                <label tabIndex="0" className="input-file-triggers"><img src={iconEdit}/></label>
                              </div>
                              <div className="content-Img">
                                {(data.image!=="")?<div className="file-return" style={{background:`url(${data.image})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain", width: "100%",height: "100%"}} alt="Noticias"></div>:""}
                                <div className="selectStatus">
                                  <input type="radio" 
                                        data-id={key} 
                                        name={data.isMain} 
                                        checked={data.isMain} onChange={this.handleChangeRadioNoticia}/> 
                                  <label className="mb-0 pl-2">Principal</label>
                                </div>
                              </div> 
                            </div>
                          </div>
                        )
                      })
                    }
                    <div className="iconadd">
                      <button className="btnIcon" onClick={this.handleAddShareholder}><img src={iconMore}/></button>
                    </div>
                  </div>
                </div>                 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="modalBtn backgroung-btnBlack px-4" onClick={this.handleReset} data-dismiss="modal">Regresar</button>
        <button type="button" className="modalBtn backgroung-btnRed px-4" onClick={this.handleSubmit}>Guardar</button>
      </div>
    </div>
    )
  }
}
export default ModalNoticia;
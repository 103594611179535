import React,{ Component } from 'react';
import icondRojo from '../../assets/file rojo small.svg';
import icondNegro from '../../assets/file negro small.svg';
class Acordion extends Component{
  constructor(){
    super()
    this.state={
      shown:[]
    }
  }
  componentDidMount(){
   this.addCompany(this.props.id)
    
  }
  addCompany = (companyinfo) => {
    let data={
      id:companyinfo,
      value:false,
    }
  	this.setState(prevState => ({
    	shown: [...prevState.shown, data],
    }));
  };
  toggleAcordion=(number)=>{
    this.props.toggle(number)
  }
  render(){
    // console.log(this.state.shown);

    return(
      <div className="card mt-1" key={this.props.id}>
      <div className="card-header" id={"heading"+this.props.id}>
          <button className="btn btn-link" type="button" data-toggle="collapse" data-target={"#collapse"+this.props.id} aria-expanded="true" aria-controls={"collapse"+this.props.id} onClick={()=>this.props.toggle(this.props.id)}>
          <i className={"fas"+(this.props.show[this.props.id] ? " fa-minus-circle" : " fa-plus-circle")}></i><span>{this.props.category}</span>
          </button>
      </div>
      <div id={"collapse"+this.props.id} className="collapse" aria-labelledby={"heading"+this.props.id} data-parent="#accordionExample">
      {
        this.props.product.map((element,key)=>{
          return (
            <div className={"card-body"+(this.props.product.length-1 === key?" ":" border-collapse")}>
            <div style={{width:"100%", marginRight:"62px"}}>
              <h3>{element.name}</h3>
              <p>{element.description}</p>
            </div>
            <div className="content-enlace">
              <a href={element.urlDataSheet} target="__blank" className="enlace" download>
                <img src={icondRojo} alt="Icono de descarga rojo"/>
                <span>FICHA TEC.</span>
              </a>
              <a href={element.urlSafetyDataSheet} target="__blank" className="enlace" download>
                <img src={icondNegro} alt="Icono de descarga negro"/>
                <span>HOJA SEG..</span>
              </a>
            </div>
          </div>
          )
        })
      }
      </div>
    </div>
    )
  }
}
export default Acordion
import React, { Component } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/footer';
import IDNoticias from './../Components/Noticias/idNoticias';
class AllNoticias extends Component{
  render(){
    var id = this.props.match.params.id.split("-")
    return(
      <div>
        <Header />
        <IDNoticias id={id[0]}/>
        <Footer history={this.props.history}/>
      </div>
    )
  }
}
export default AllNoticias 
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import SlideNav from './SlideNav';
import logo from "../assets/Logos uniservice-07 (1).svg"
class Header extends Component{
  constructor(){
    super();
    this.state={
      isOpen:false
    }
  }
  componentDidMount(){
    document.addEventListener("click",this.handleClick,false);
  }

  componentWillUnmount(){
    document.removeEventListener("click",this.handleClick,false)
  }

  handleClick=(e)=>{
    if (!this.refs.root.contains(e.target) && this.state.isOpen === true) {
      this.setState({
      isOpen: false
    });
    };
  }

  menuToggle=(e)=>{
    e.stopPropagation();
    this.setState({
      isOpen:!this.state.isOpen
    })
  }

  render(){
    let menuStatus = this.state.isOpen ? 'isopen' : '';
    return(
      <header ref="root" className="main">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <Link className="navbar-brand" to="/"><img src={logo} alt="logo de uniservice" /></Link>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item showNavItem">
              <Link to="/" className={"nav-link"+(this.props.name==="Inicio"?" underLine":" inactive")}>Inicio<hr />
              </Link>
            </li>
            <li className="nav-item showNavItem">
              <Link to="/Catalogos-Quimicos" className={"nav-link"+(this.props.name==="Quimicos"?" underLine":" inactive")}>Catálogo y Fichas Técnicas<hr />
              </Link>
            </li>
            <li className="nav-item showNavItem">
              <Link to="/Test-kits" className={"nav-link"+(this.props.name==="test"?" underLine":" inactive")}>Test Kits<hr />
              </Link>
            </li>
            <li className="nav-item showNavItem">
              <Link to="/Limites-Diarios" className={"nav-link"+(this.props.name==="limites"?" underLine":" inactive")}>Límites Químicos y Diarios de Control<hr />
              </Link>
            </li>
            <li className="menu-nav">
              <div className="hambclicker" onClick={ this.menuToggle }></div>
              <div id="hambmenu" className={ menuStatus }><span></span><span></span><span></span><span></span></div>
            </li>
          </ul>
        </nav>
      <SlideNav slideClass={menuStatus}>
        <li className="nav-item">
          <Link to="/" className="nav-link" >Inicio</Link>
        </li>
        <li className="nav-item">
          <Link to="/Catalogos-Quimicos" className="nav-link" >Catálogo y Fichas Técnicas</Link>
        </li>
        <li className="nav-item">
          <Link to="/Test-kits" className="nav-link">Test Kits</Link>
        </li>
        <li className="nav-item">
          <Link to="/Limites-Diarios" className="nav-link">Límites Químicos y Diarios de Control</Link>
        </li>
      </SlideNav>
    </header>
    )
  }
}
export default Header;
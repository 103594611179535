import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import imgQuimicos from '../../assets/newAssets/blue-containers-drums-615670.jpg'
import imgAsesorias from '../../assets/newAssets/helloquence-61189-unsplash.jpg'
import imgHistoria from '../../assets/newAssets/architecture-bay-boats-799091.jpg'
//import filePdf from '../../assets/newAssets/Información de Productos Uniservice al 14.06.2019 (Versión 2).pdf'
import filePdf from '../../assets/newAssets/product-information-uniservice.pdf'
import downloadIcon from '../../assets/icons8-exportar-pdf.svg'
class Content extends Component{
    render(){
        return(
            <section className="first-section">
                <div className="content-welcome">
                    <div className="container-fluid">
                        <div className="row columns">
                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 border-col lines px-0 showList">
                                <div className="lines-specific">
                                    <p className="nav-link">Catálogo Resumen de Químicos</p>
                                    <a className="fileDownload" href={filePdf} target="_blanck">
                                        <img src={downloadIcon} alt="icono download"/><span>Descargar Catálogo de Químicos</span>
                                    </a>
                                </div>
                                <img className="img-line oscurecer" src={imgQuimicos} alt="Quimicos"/>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-4 border-col lines px-0">
                                <a className="nav-link" href="#contacto">Asesorías y Contacto</a>
                                <img className="img-line oscurecer" src={imgAsesorias} alt="Asesorias"/>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-4 border-col lines px-0">
                                <Link className="nav-link" to="/Historia">Historia</Link>
                                <img className="img-line oscurecer" src={imgHistoria} alt="Historia"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="imgInfo-content">
                    <div className="info-uniserve">
                        <p className="text2">Bienvenidos Ingenieros y Pilotos Mercantes a la web más amigable para su labor diaria a bordo</p>
                    </div>
                </div>
            </section>
        )
    }
}
export default Content;
import React, { Component } from 'react';
import fondo from '../../assets/cielo.png';
import logo from '../../assets/logoUniservice1.svg';
class Header extends Component{
  LogOut=()=>{
    this.props.auth()
    this.props.history.replace('/');
  }
  render(){
    return(
      <header className="Intranet">
          <nav className="navbar navbar-expand-lg navbar-dark background-black">
            <a className="navbar-brand" href="/index.html"><img src={logo} alt="Logo de Uniservice"/></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-333"
              aria-controls="navbarSupportedContent-333" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent-333">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <button className="nav-link" onClick={this.LogOut}>Cerrar Sesión<i className="fas fa-times-circle"></i>
                  </button>          
                </li>
              </ul>
            </div>
          </nav>
        <div className="title-section">
          <div>
            <img src={fondo} alt="fondo titulo"/>
          </div>
          <div className="titleContent">
            <h3>Intranet</h3>
          </div>
        </div>
      </header>
    )
  }
}
export default Header;
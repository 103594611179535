import React, { Component } from 'react';
import { URLPutLimits,URLDeleteLimits } from '../../../auth/apis';
import iconMore from '../../../assets/icons8-más-filled.svg';
import iconExcel from '../../../assets/icons8-xls-filled.svg';
import iconPdf from '../../../assets/icons8-pdf-2-filled.svg';
import axios from 'axios'
class ModalKits extends Component{
  constructor(props){
    super(props);
    this.state={
      codigo:"",
      categoria:"",
      nameFile:{},
      extFile:{},
      files:[],
      newFiles:[]
    }
  }

  componentDidMount = async() =>{
    const { newFiles } = this.state
    this.props.files.forEach(data=>{
      newFiles.push({
        id:data.id,
        name:data.name,
        file:data.url,
      })
    })
    await this.setState({newFiles})
    await this.setState({
      codigo:this.props.codigo,
      categoria:this.props.categoria,
    })
  }

  getFileExtension1=(filename) =>{
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  createSelectItems=()=>{
    const { selects} = this.state;
    let items=[];
    selects.forEach(data=>{      
      items.push(<option key={data.id} value={data.id}>{data.name}</option>)
    })
    return items;
  }
  handleShareholderNameChange =  e => {
    if(["name","file"].includes(e.target.name)){
      let files = [...this.state.files];
      files[e.target.dataset.id][e.target.name]=e.target.value;
      this.setState({files})
    }else{
      this.setState({[e.target.name]:e.target.value})
    }
  };

  handleAddShareholder = () => {
    this.setState((prevState) =>({
      files:[...prevState.files, {name:"",file:""}],
    }));
  };

  handleRemoveShareholder = idx => () => {
    this.setState({
      files: this.state.files.filter((s, sidx) => idx !== sidx)
    });
  };
  handleReset = ()=>{
    this.setState({
      codigo:this.props.codigo,
      categoria:this.props.categoria,
      nameFile:{},
      extFile:{},
      files:[],
      newFiles:this.state.newFiles
    })
  }
  handleDelete = idx => () => {
    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    axios.delete(URLDeleteLimits(idx),{headers})
    .then(res=>{
      console.log(res);
    })
  };

  handleShareholderNameChangeFile = e => {    
    let id=e.target.dataset.id;
    let name=e.target.name
    let extFile = this.getFileExtension1(e.target.files[0].name)
    
    this.setState({
      nameFile:{
        ...this.state.nameFile,
        [id]:e.target.files[0].name},
      extFile:{
        ...this.state.extFile,
        [id]:extFile
      }
      })
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e)=>{
      let files = [...this.state.files]
      files[id][name]=reader.result;      
      this.setState({files})
    }
  };
  handleShareholderNameChangeNewFile = e => {    
    let id=e.target.dataset.id;
    let name=e.target.name
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e)=>{
      let newFiles = [...this.state.newFiles]
      newFiles[id][name]=reader.result;      
      this.setState({ newFiles })
    }
  };

  handleSubmit = () =>{
    const { codigo,files,newFiles } = this.state;  
    const { getData } = this.props;
  
    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    const body={
      "files":files.concat(newFiles)
    }    
    axios.put(URLPutLimits(codigo),body,{headers})
    .then(res=>{
      console.log(res);
      if(res.status===200){
        alert("Se guardo correctamente los datos")
        getData()

      }else{
        alert("Problemas con servidor, constacte a su proveedor")
      }
    })
  }

  render(){        
    return(
      <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Editar Límites y Diarioss</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-8">
              <label className="label" htmlFor="form3">Categoria</label>
                <div className="md-form">
                  <input type="text" id="form1" className="form-control" value={this.props.categoria} onChange={this.handleShareholderNameChange} disabled/>
                </div>
              </div>
              <div className="col-12 mt-3 d-flex" style={{alignItems:"center"}}>
                <div>
                  <p className="mb-0 titleLimitProdut">Nombre del Documento</p>
                </div>
                <div className="limitIconAdd">
                  <button className="btnAdd" onClick={this.handleAddShareholder}><img src={iconMore}/></button>
                </div>
              </div>
              {
                this.state.newFiles.map((data,idx)=>{
                  return(
                    <div className="col-12" key={idx}>
                    <div className="d-flex px-15 justify-content-between info-product Limit">
                      <div className="">
                        <div className="md-form">
                          <input 
                            type="text"
                            data-id={idx}
                            name="name" 
                            className="form-control" 
                            value={data.name} disabled/>
                        </div>
                      </div>
                      <p className="iconopdf limit">
                        {(data.file!=="")?
                          <img src={(this.state.extFile[idx] === "xlsx" || this.state.extFile[idx] === "xls")?iconExcel:iconPdf} alt="imageFondo"/>:""
                        }
                      </p>
                      <div className="input-file-container">  
                        <input 
                          className="input-file" 
                          data-id={idx}
                          name="file" 
                          type="file" onChange={this.handleShareholderNameChangeNewFile}/>
                        <label tabIndex="0" className="input-file-trigger mb-0">Reemplazar archivo</label>
                      </div>
                      <button className="btnDelete ml-0" onClick={this.handleDelete(data.id)}>Eliminar</button>
                    </div>
                  </div>
                  )
                })
              }
              {
                this.state.files.map((file,idx)=>{
                  let fileId=`file-${idx}`, nameId=`name-${idx}`
                  return (
                  <div className="col-12" key={idx}>
                    <div className="d-flex px-15 justify-content-between info-product">
                      <div className="">
                        <label>Ingresa Producto</label>
                        <div className="md-form">
                          <input 
                            type="text"
                            data-id={idx}
                            id={nameId} 
                            name="name" 
                            className="form-control" 
                            value={file.name} onChange={this.handleShareholderNameChange}/>
                        </div>
                      </div>
                      <p className="iconopdf">
                        {!((/\.(jpg|png|gif|jpeg)$/i).test(file.file.substring("data:image/".length, file.file.indexOf(";base64"))))?
                          <img src={file.file} alt="imageFondo"/>:""
                        }
                      </p>
                      <div className="input-file-container">  
                        <input 
                          className="input-file" 
                          data-id={idx}
                          id={fileId} 
                          name="file" 
                          type="file" onChange={this.handleShareholderNameChangeFile}/>
                        <label tabIndex="0" className="input-file-trigger">Reemplazar archivo</label>
                      </div>
                      <button className="btn" onClick={this.handleRemoveShareholder(idx)}>Eliminar</button>
                    </div>
                  </div>
                  )
                })
              }
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="modalBtn backgroung-btnBlack px-4" data-dismiss="modal">Regresar</button>
            <button className="modalBtn backgroung-btnRed px-4" type="submit" value="submit" onClick={this.handleSubmit}>Guardar</button>
          </div>
      </div>
    )
  }
}
export default ModalKits;
import React, { Component } from 'react';
import Modal from './Modal';
import { URLGetKits } from './../../auth/apis';
import ModalCreate from './ModalCreate'
import axios from 'axios';
import moment from 'moment'
class TableKits extends Component{
    constructor(){
    super()
    this.state={
      data:[]
    }
  }
  
  componentDidMount = async () =>{
    let newdata = []
    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    await axios.get(URLGetKits,{headers})
    .then(res=>{
      res.data.testKits.map(element => {       
        let tempData={
          id:element.id,
          name:element.name,
          description:element.description,
          urlImage:element.urlImage,
          urlFile:element.urlFile,
          dateCreated:element.dateCreated,
          isActive:(element.isActive)?"Activo":"Inactivo"
        }
        return newdata.push( tempData )
      });
    })
    await this.setState({ data:newdata })
  }
  getKit = async () =>{
    let newdata = []
    const headers ={
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    await axios.get(URLGetKits,{headers})
    .then(res=>{
      res.data.testKits.map(element => {       
        let tempData={
          id:element.id,
          name:element.name,
          description:element.description,
          urlImage:element.urlImage,
          urlFile:element.urlFile,
          dateCreated:element.dateCreated,
          isActive:(element.isActive)?"Activo":"Inactivo"
        }
        return newdata.push( tempData )
      });
    })
    await this.setState({ data:newdata })
  }
  render(){
    return(
      <div className="tab-pane fade" id="testKit" role="tabpanel" aria-labelledby="testKit-tab">
      <div className="addbtn">
        <button type="button" data-toggle="modal" data-target="#Kit-add" className="modalBtn backgroung-btnRed">Nuevo Kit</button>
        <ModalCreate name="Kit" codigo="-add" getData={()=>this.getKit()}/>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-background">
          <thead>
            <tr>
              <th className="px-2" scope="col">Código</th>
              <th className="px-2" scope="col">Nombre</th>
              <th className="px-2" scope="col">Descripción</th>
              <th className="px-2" scope="col">Fecha de creación</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
          {
            this.state.data.map((data,key)=>{
              const time= moment(data.dateCreated).format("DD-MM-YYYY")
              return(
                <tr key={key}>
                  <td className="px-2">{data.id}</td>
                  <td className="px-2">{data.name}</td>
                  <td className="px-2"><p className="descrip">{data.description}</p></td>
                  <td className="px-2">{time}</td>
                  <td>                
                    <button type="button" className="linkMore" data-toggle="modal" data-target={"#kits"+data.id}>
                      Editar
                    </button>
                    <Modal 
                      name="kits" 
                      codigo={data.id}
                      nombre={data.name}
                      description={data.description}
                      urlImage={data.urlImage}
                      urlFile={data.urlFile}
                      dateCreated={data.dateCreated}
                      isActive={data.isActive}
                      getData={()=>this.getKit()}
                      />
                  </td>
                </tr>
                )
              }       
            )
          }
          </tbody>
        </table>
      </div>
    </div>
    )
  }
}
export default TableKits;
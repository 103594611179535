import React, { Component } from 'react';
import fondo from '../../assets/cielo.png';
import axios from 'axios';
import { URLHomeNoticiasId } from '../../auth/apis';
class IDNoticias extends Component{
  constructor(){
    super()
    this.state={
      listItems:[]
    }
  }
  componentWillMount = async () =>{
    await this.getNewId()
  }
  getNewId = async () =>{
    let newData =  [];
    await axios.get(URLHomeNoticiasId(this.props.id))
    .then(res=>{      
      console.log(res);
      
      let tempData={
        id:res.data.id,
        title:res.data.title,
        content:res.data.longDescription,
        img:res.data.images
      }
      newData.push( tempData )
    })
    await this.setState({ listItems:newData })
  }
  urlify = (text) =>{
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
}
  render(){
    console.log(this.state.listItems);
    
    return(
      <section className="section-noticia">
        <div className="title-section">
          <div>
            <img src={fondo} alt="fondo titulo"/>
          </div>
          <div className="titleContent">
            <h3>Noticias</h3>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 mt-5 mb-5" >
              {this.state.listItems.map((data,i)=>
                (<div className="content-certificaciones">
                  <h3>{data.title}</h3>
                  <div className="body-info">
                    {
                      data.content.split("\n").map(text=>{
                        
                        const urlRegex = /(https?:\/\/[^\s]+)/g;
                        const url = text.match(urlRegex)
                        if(url === null){
                          return <p className="mb-2">{text}</p>
                        }else{
                          const urlText = text.split("http://");
                          console.log(urlText);
                          return <p className="mb-2">{urlText[0]}<a href={"http://"+urlText[1]} target="__blanck">{"http://"+urlText[1]}</a></p>

                        }

                      })
                    }
                  </div>
                  <div className="images">
                      {
                        data.img.map(image=>{
                          return <img src={image} alt="noticias"/>
                        })
                      }
                  </div>
                </div>)     
              )}
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default IDNoticias 